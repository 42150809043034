import ClientAanvraagDetailsPage from "../pages/customer/AanvraagDetails";
import ClientBetalingDetailsPage from "../pages/customer/BetalingDetails";
import ClientEIAAanvragenPage from "../pages/customer/EIAAanvragen";
import ClientEIAZonAanvragenPage from "../pages/customer/EIAZonAanvragen";
import IsdeAanvragenPage from "../pages/customer/IsdeAanvragen";
import AdminDashboardPage from "../pages/admin/Dashboard";
import ClientFacturenPage from "../pages/customer/Facturen";
import ClientDashboardPage from "../pages/customer/Dashboard";
import AdminEIAAanvragenPage from "../pages/admin/EIAAanvragen";
import AdminEIAZonAanvragenPage from "../pages/admin/EIAZonAanvragen";
import AdminIsdeAanvragenPage from "../pages/admin/IsdeAanvragen";
import AdminFinancieelPage from "../pages/admin/Financieel";
import AdminAanvraagDetailsPage from "../pages/admin/AanvraagDetails";
import AdminKlantenPage from "../pages/admin/Klanten";

const routes = [

  {
    path: "admin/dashboard",
    component: AdminDashboardPage,
  },
  {
    path: "admin/klanten",
    component: AdminKlantenPage,
  },
  {
    path: "admin/eia-overzicht",
    component: AdminEIAAanvragenPage,
  },
  {
    path: "admin/eia-zon-overzicht",
    component: AdminEIAZonAanvragenPage,
  },
  {
    path: "admin/isde-overzicht",
    component: AdminIsdeAanvragenPage,
  },
  {
    path: "admin/financieel",
    component: AdminFinancieelPage,
  },
  {
    path:'admin/eia-zon-overzicht/aanvraag-details/:aanraagId',
    component: AdminAanvraagDetailsPage,
  },
  {
    path:'admin/eia-overzicht/aanvraag-details/:aanraagId',
    component: AdminAanvraagDetailsPage,
  },
  {
    path:'admin/isde-overzicht/aanvraag-details/:aanraagId',
    component: AdminAanvraagDetailsPage,
  },

  {
    path: "customer/dashboard",
    component: ClientDashboardPage,
  },
  {
    path:'customer/eia-aanvragen',
    component: ClientEIAAanvragenPage,
  },
  {
    path:'customer/eia-zon-aanvragen',
    component: ClientEIAZonAanvragenPage,
  },
  {
    path:'customer/isde-aanvragen',
    component: IsdeAanvragenPage,
  },
  {
    path:'customer/facturen',
    component: ClientFacturenPage,
  },
  {
    path:'customer/facturen/betaling-details/:facturenId',
    component: ClientBetalingDetailsPage,
  },
  {
    path:'customer/dashboard/aanvraag-details/:aanraagId',
    component: ClientAanvraagDetailsPage,
  }
];
export default routes;
