import React, { useEffect, useState } from "react";
import Button from "./button";

const AdminUpdateUserLimitsModal = ({ isOpen, onClose, onSubmit, rateLimitHour, rateLimitDay }) => {
  const [hourLimit, setHourLimit] = useState(0);
  const [dayLimit, setDayLimit] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    const modal = document.getElementById("update-limits-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }

  useEffect(() => {
    setHourLimit(rateLimitHour);
    setDayLimit(rateLimitDay);
  }, [rateLimitHour, rateLimitDay]);

  const submitForm = () => {
    if (hourLimit < 0 || dayLimit < 0) {
      setError("Both limits must be greater than zero");
      return;
    }
    setError("");
    onSubmit({ rateLimitHour: hourLimit, rateLimitDay: dayLimit });
    handleClose();
  };

  return (
    <dialog id="update-limits-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box">
        <div className="modal-content">
          <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>
          <h1 className="modal-title text-center">
            <strong>Update User Rate Limits</strong>
          </h1>
          <form className="update-profile-form mt-5">
            <div className="grid md:grid-cols-1 gap-10 mt-3 grid-cols-1">
              <div>
                <label htmlFor="hourLimit" className="text-sm">Hourly Rate Limit</label>
                <input
                  id="hourLimit"
                  name="hourLimit"
                  type="number"
                  placeholder="Enter hourly rate limit"
                  className={`appearance-none w-full py-2 px-3 dark:bg-white`}
                  value={hourLimit}
                  onChange={(e) => setHourLimit(Number(e.target.value))}
                />
              </div>
              <div>
                <label htmlFor="dayLimit" className="text-sm">Daily Rate Limit</label>
                <input
                  id="dayLimit"
                  name="dayLimit"
                  type="number"
                  placeholder="Enter daily rate limit"
                  className={`appearance-none w-full py-2 px-3 dark:bg-white`}
                  value={dayLimit}
                  onChange={(e) => setDayLimit(Number(e.target.value))}
                />
              </div>
              <div className="invalid-feedback h-3 mb-4">
                {error}
              </div>
            </div>
            <div className="py-2 flex md:justify-end justify-center">
              <Button btnClassNames={`btn-neutral w-min px-4`} text={'Update Limits'} type={'button'} onClick={submitForm} />
            </div>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default AdminUpdateUserLimitsModal;
