import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { fetchSearchSuggestions } from '../core/axios/endpoints';
import _ from 'lodash';

const SearchComponent = ({ onSearch,type }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = (suggestion) => {
    onSearch(suggestion||searchTerm);  // Trigger the search operation
  };

  const handleClear = () => {
    setSearchTerm('');
    onSearch('');  // Clear the search externally as well
  };

  const fetchSuggestions = _.debounce(async (value) => {
    if(!type || !value) return;
    setIsLoading(true);
    fetchSearchSuggestions(type,value).then((response) => {
      setSuggestions(response);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      setIsLoading(false);
    })
  }, 300);

  return (
    <div className='search-component' >
      
    <div className="relative w-full dropdown dropdown-end">
  <div tabIndex={0} >
      <input
        type="text"
        className="input w-full rounded-full pl-4 pr-16 py-3 border focus:border-none focus:ring-0 focus:outline-none"
        placeholder="Zoeken"
        value={searchTerm}
        onInput={(e) => {setSearchTerm(e.target.value); fetchSuggestions(e.target.value);}}
        onKeyDown={(e) => e.key === 'Enter' && handleSearch(searchTerm)}
      />
      {searchTerm && (
        <button
          onClick={handleClear}
          className="absolute inset-y-0 right-10 flex items-center px-2 cursor-pointer"
        >
          <FeatherIcon icon="x-circle" size={20} className="text-gray-500"/>
        </button>
      )}
      <button
        onClick={handleSearch}
        className="absolute inset-y-0 right-0 flex items-center px-4 rounded-r-full cursor-pointer"
      >
        <FeatherIcon icon="search" size={20} className="text-gray-500"/>
      </button>
    </div>
   { suggestions?.length ? 
   <ul tabIndex={0} className="dropdown-content z-[1] menu shadow bg-base-100 rounded-box w-52">
    
   {!isLoading && (
     <ul className='suggestion'>
      {suggestions.map((suggestion, index) => (
        <li key={index} className='suggestion-item' onClick={()=>{setSearchTerm(suggestion); handleSearch(suggestion); setSuggestions([])}}>
          <a > {suggestion}</a>
        </li>
        
      ))}
    </ul> 
   )}

 {isLoading && (
   <SkeletonTheme baseColor="#ebebeb" highlightColor="#f1f1f1">
    <div className='pb-2 px-2'>
    <Skeleton height={25} className='mt-3' borderRadius={10} count={2} />
    </div>
 </SkeletonTheme>

 )}
    </ul>
     : ''
    }
</div>

    </div>
  );
};

export default SearchComponent;
