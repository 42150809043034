import FeatherIcon from 'feather-icons-react';
import React from 'react';

const Button = ({ text,btnClassNames,onClick,svgClassNames,isLoading,loadingText, icon,type }) => {
    return (
        <button disabled={isLoading} className={`disabled:pointer-events-none disabled:bg-opacity-50 ${btnClassNames}`} onClick={onClick} type={type} >
        {
        isLoading ?
         <span className='flex items-center gap-1'>
            <FeatherIcon icon="loader" className={`w-4 h-4 animate-spin ${svgClassNames}`} />
         {loadingText}
         </span>
        : icon ?
        <span className='flex items-center gap-1'>
            <FeatherIcon icon={icon} className={`h-4 w-4`} />
         {text}
         </span>
          : text
      }
      </button>
    )
}

export default Button;