import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";

export const toastrSuccess = (message) => {
  alertify.set('notifier','delay', 2);
  alertify.set('notifier', 'position', 'top-right');
  alertify.success(message);
};

export const toastrError = (message) => {
  alertify.set('notifier','delay', 2);
  alertify.set('notifier', 'position', 'top-right');
  alertify.error(message);
};