
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Logo } from "../core/path/images";
import FeatherIcon from "feather-icons-react";
import {  useNavigate } from "react-router-dom";
import { toastrError, toastrSuccess } from "../utils/alertify";
import { ErrorsAndMessages, ErrorsAndMessagesr } from "../utils/constants";
import { forgetPassword } from "../core/axios/endpoints";



const ForgetPasswordPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(ErrorsAndMessages.emailRequired).email(ErrorsAndMessages.emailInvalid),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });



  const submitForm = (data) => {
    setIsLoading(true);
    forgetPassword(data).then((user) => {
      toastrSuccess(data.message || ErrorsAndMessages.emailSent);
      setIsSuccess(true);
      setSuccessMessage(data.message || ErrorsAndMessages.emailSent);
    }).catch((err) => {
      toastrError(err.response?.data?.error || ErrorsAndMessages.emailInvalid);
    }).finally(() => {
      setIsLoading(false);
    })
  }


  return (
    <>
      <div className="main-wrapper">
        <div className="forget-password-page">
          <div className="logo md:m-0 mx-8">
            <img src={Logo} alt="" />
          </div>

          <div className="sign-in-form">
            <h1>Wachtwoord vergeten</h1>
            <p className="my-2">
            Vul uw e-mailadres in, wij sturen u een link om uw wachtwoord opnieuw in te stellen.
            
            </p>
            <form  onSubmit={handleSubmit((data) => submitForm(data))}>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                id="email"
                name="email"
                type="text"
                placeholder="Voer uw e-mailadres in"
                autoComplete="new-password"
                  className={`${errors.email ? "is-invalid" : ""} appearance-none w-full py-2 px-3`}
                  {...register("email")}
                />
                <div className="invalid-feedback h-3">{errors.email?.message || " "}</div>
              </div>

         
             <div className="mt-5 p-2">
             <button disabled={isSuccess} className="btn btn-active btn-neutral w-full disabled:opacity-50">
                    {
                      isLoading ?
                      <span className='flex items-center gap-1'>
                      <FeatherIcon icon="loader" className={`w-4 h-4 animate-spin`} />
                      Valideren...
                      </span>
                      : "Verstuur"
                    }
             </button>

             { isSuccess && <div className="text-green-500 mt-2 text-center">
             Er is een resetlink naar uw e-mailadres verzonden. Controleer uw mailbox om uw wachtwoord opnieuw in te stellen.
             </div>}
             </div>
            </form>
            <div className="sign-up-link">

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordPage;
