import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../components/button";
import { createApplication, fetchUserCompanyDetails, getApplicationById, updateApplication, uploadImage } from "../../core/axios/endpoints";
import { toastrError, toastrSuccess } from "../../utils/alertify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NETWORK_ERROR, RoutesFullPath } from "../../utils/constants";
import { useUser } from "../../core/context/user";
import FeatherIcon from "feather-icons-react";
import PageLoader from "../../components/page-loader";
import { AantekeningVoorOnsPopOver, AchterNaamPopOver, DatumOpdrachtBevestigingPopOver, EmailPopOver, HandelsNaamPopOver, HuisNummerPopOver, KopieOndertekendeOffertePopOver, KvkNummerPopOver, MeldCodePopOver, PlaatsPopOver, PostCodePopOver, KenmerkPopOver, StraatNaamPopOver, TelefoonNummerPopOver, VoorLettersPopOver, BedrijfsMiddelCodePopOver } from "../../components/custom-popovers";

const ClientEIAAanvragenPage = (props) => {
  const [validationSchema, setValidationSchema] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { userData } = useUser();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isError, setError] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [updateAppPricing, setUpdateAppPricing] = useState({});
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isAutoFillLoading, setIsAutoFillLoading] = useState(false);
  const [isAutoFill, setIsAutoFill] = useState(false);
  const [KvkNummer, setKvkNummer] = useState('');
  const [lastFetchedKvkNummer, setLastFetchedKvkNummer] = useState('');
  const [middelCodes, setMiddelCodes] = useState(['']);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const applicationId = state?.applicationId;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (applicationId) {
      setIsUpdate(true);
      getAanraagDetails();
    }
  }, [applicationId]);

  const getAanraagDetails = async () => {
    setIsFullScreenLoading(true);
    await getApplicationById(applicationId).then((data) => {
      setUpdateAppPricing({
        exclusiveBTW: data.exclusiveBTW,
        inclusiveBTW: data.inclusiveBTW,
        totaal: data.totaal,
      });
      delete data.createdAt;
      delete data.updatedAt;
      delete data.klantId;
      delete data.regling;
      delete data.applicationFee;
      delete data.tax;
      delete data.status;
      delete data._id;
      delete data.__v;
      delete data.isDeleted;
      delete data.afgekeurdReason;
      delete data.inclusiveBTW;
      delete data.exclusiveBTW;
      delete data.totaal;
      delete data.meldCodes;
      delete data.referentieNummer;
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
      setUploadedFiles(data.quotationCopyFiles.map(file => ({ name: getFileNameFromUrl(file), url: file })));
      setMiddelCodes(data?.bedrijfsmiddelCode || ['']);
    }).catch(err => {
      setError(true);
      if (err?.isNetworkError) {
        setIsNetworkError(true);
      }
    }).finally(() => {
      setIsFullScreenLoading(false);
    });
  };

  const getFileNameFromUrl = (url) => {
    // Use URL constructor to parse the given URL
    const parsedUrl = new URL(url);
    // Get the pathname from the URL
    const pathname = parsedUrl.pathname;
    // Split the pathname by '/' and return the last part
    const segments = pathname.split('/');
    return segments.pop();
  }


  const schema = () => {
    return Yup.object().shape({
      KvkNummer: Yup.string().required("KVK Nummer is verplicht").max(8, "KVK Nummer moet 8 cijfers bevatten").matches(/^[0-9]*$/, "KVK Nummer mag alleen cijfers bevatten"),
      VoorLetters: Yup.string().required("Voorletters is verplicht"),
      geslacht: Yup.string().required("Geslacht is verplicht"),
      postCode: Yup.string()
        .required("Postcode is verplicht")
        .matches(/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/, "Foutieve postcode format"),
      straatNaam: Yup.string().required("Straat Naam is verplicht"),
      email: Yup.string().required("Email is verplicht").email("Email is incorrect"),
      Kenmerk: Yup.string().required("Kenmerk is verplicht"),
      aantekeningVoorOns: Yup.string().optional(),
      handelsNaam: Yup.string().required("Handelsnaam is verplicht"),
      achterNaam: Yup.string().required("Achternaam is verplicht"),
      huisNummer: Yup.string().required("Huisnummer is verplicht"),
      plaats: Yup.string().required("Plaats is verplicht"),
      teleFoonNummer: Yup.string().required("Telefoonnummer is verplicht"),
      datumOpdrachtBevestiging: Yup.string()
        .required('Datum Opdracht Bevestiging is verplicht')
        .test('is-date', 'Invalid date. Required format: yyyy-mm-dd', (value) => {
          return /^(\d{4})-(\d{2})-(\d{2})$/.test(value);
        }),
    })
  };

  useEffect(() => {
    setValidationSchema(schema());
  }, []);

  const submitForm = (data) => {

    if (uploadedFiles?.length < 1) {
      toastrError("Upload minimaal één bestand");
      return;
    }

    if (isMiddleCodeInvalid()) {
      toastrError(isMiddleCodeInvalid());
      return;
    }

    const getQuotationFiles = () => {
      return uploadedFiles.map((file) => file.url);
    };

    const FilteredMiddelCodes = middelCodes?.filter(code => code).map(code => code.trim());
    
    setIsLoading(true);
    if (isUpdate) {
      updateApplication(applicationId, {
        ...data, regling: 'EIA', quotationCopyFiles: getQuotationFiles(),
        bedrijfsmiddelCode: FilteredMiddelCodes
      })
        .then((res) => {
          toastrSuccess(res?.successMsg || "Aanvraag succesvol geüpload.");
          navigate(RoutesFullPath.customerDashboard);
        })
        .catch((err) => {

          toastrError(err?.response?.data?.error || "Er is een fout opgetreden. Probeer het later opnieuw.");
        })
        .finally(() => {
          setIsLoading(false);
          setIsSubmitted(false);
        });
    } else {
      createApplication({
        ...data, regling: 'EIA', quotationCopyFiles: getQuotationFiles(),
        bedrijfsmiddelCode: FilteredMiddelCodes
      })
        .then((res) => {
          toastrSuccess(res?.successMsg || "Aanvraag succesvol aangemaakt");
          navigate(RoutesFullPath.customerDashboard);
        })
        .catch((err) => {

          toastrError(err?.response?.data?.error || "Er is een fout opgetreden. Probeer het later opnieuw.");
        })
        .finally(() => {
          setIsLoading(false);
          setIsSubmitted(false);
        });
    }
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    setIsUploadLoading(true);
    uploadImage(file).then((res) => {
      setUploadedFiles((prev) => [...prev, { name: file.name, url: res?.data?.url }]);

    }).catch((err) => {

    }).finally(() => {
      setIsUploadLoading(false);
    });
  };

  const selectAndUploadFile = () => {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf, .jpg, .jpeg, .png';
    input.onchange = (e) => {
      uploadFile(e);
    };
    input.click();
  };

  const openUrlInNewTab = (url) => {
    window.open(url, '_blank');
  }

  const isKvkNummerValid = () => {
    if (!KvkNummer || KvkNummer.length < 8 || KvkNummer.length > 8 || isNaN(KvkNummer)) {
      return false;
    }
    return true;
  }
  const getKvkData = async () => {
    if (!isKvkNummerValid()) {
      toastrError('Vul een geldig KVK-nummer in.');
      return;
    }
    setIsAutoFillLoading(true);
    await fetchUserCompanyDetails(KvkNummer).then(data => {

      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
      setIsAutoFill(true);
      setLastFetchedKvkNummer(KvkNummer);
    }).catch(err => {
      toastrError(err?.response?.data?.error || "Er is een fout opgetreden. Probeer het later opnieuw.");

    }).finally(() => {
      setIsAutoFillLoading(false);
    });
  }

  const checkNewKvkNummer = (kvk) => {
    if (kvk !== lastFetchedKvkNummer || !kvk) {
      setIsAutoFill(false);
    } else {
      setIsAutoFill(true);
    }
  }

  const formatNumber = (number) => {
    return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const createMiddleCode = () => {
    if (middelCodes.length < 5) {
      setMiddelCodes((prev) => [...prev, '']);
    }
  }

  const removeMiddleCode = (index) => {
    setMiddelCodes((prev) => prev.filter((item, i) => i !== index));
  }

  const isMiddleCodeInvalid = () => {
    const isFirstEmpty = middelCodes[0] === '';
    if (isFirstEmpty) {
      return 'Voeg minimaal één middelCode toe.';
    }
    return '';
  }


  if (isFullScreenLoading) {
    return <PageLoader />;
  }

  if (isError) {
    return (
      <div className="main-wrapper">
        <div className="error-box">
          <h1>{isNetworkError ? "503" : "404"}</h1>
          <h3 className="h2 mb-3">
            <i className="fas fa-exclamation-circle" /> Oops! {isNetworkError ? "Network Error!" : ""}
          </h3>
          <p className="h4 font-weight-normal mt-2">
            {isNetworkError ? NETWORK_ERROR : "Er is iets misgegaan. Probeer het later opnieuw."}
          </p>
          <button onClick={() => navigate(-1)} className="btn customized-btn mt-2 animate-none bg-[#A5A5A5] text-white border-none px-7 shadow-none py-3 rounded-[5px]">
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content client-eia-aanvragen-page">
          <div className="grid md:grid-cols-[65%35%] grid-cols-1 mt-3 ">
            <form
              className="update-profile-form"
              onSubmit={handleSubmit((data) => { submitForm(data); })}
            >
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-[100px]">

                {/* line 1 */}
                <div >

                <div className="relative mb-3">
                  <label htmlFor="KvkNummer"> KvK-Nummer Klant </label>
                  <KvkNummerPopOver />
                  <input
                    id="KvkNummer"
                    name="KvkNummer"
                    type="text"
                    autoComplete="new-password"
                    onInput={(e) => { setKvkNummer(e.target.value); checkNewKvkNummer(e.target.value) }}
                    className={`${errors.KvkNummer ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("KvkNummer")}
                  />
                  {<button disabled={isAutoFill || !isKvkNummerValid() || isAutoFillLoading} type="button" className="btn btn-outline absolute h-[30px] top-[27px] right-[40px] kvk-nummer-btn" onClick={getKvkData}>
                    {isAutoFillLoading ? <span className="loading loading-spinner"></span> : ''}
                    {isAutoFillLoading ? 'Fetching..' : isAutoFill ?
                      <FeatherIcon icon="check-circle" className="h-5 text-green-500" />
                      : 'Automatisch invullen'}
                  </button>}
                  <div className="invalid-feedback h-3">
                    {errors.KvkNummer?.message}
                  </div>
                </div>

                <div className="relative mb-3">
                  <label > Handelsnaam </label>
                  <HandelsNaamPopOver />
                  <input
                    id="handelsNaam"
                    name="handelsNaam"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.handelsNaam ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("handelsNaam")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.handelsNaam?.message}
                  </div>
                </div>


                <div className="relative mb-3">
                  <label htmlFor="VoorLetters">Voorletters </label>
                  <VoorLettersPopOver />
                  <input
                    id="VoorLetters"
                    name="VoorLetters"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.VoorLetters ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("VoorLetters")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.VoorLetters?.message}
                  </div>
                </div>

                <div className="relative mb-3">
                  <label htmlFor="achterNaam"> Achternaam </label>
                  <AchterNaamPopOver />
                  <input
                    id="achterNaam"
                    name="achterNaam"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.achterNaam ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("achterNaam")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.achterNaam?.message}
                  </div>
                </div>


                <div className="relative mb-3">
                  <label htmlFor="geslacht"> Geslacht </label>
                  <div className="flex gap-10">
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input type="radio" id="man" name="man" value={'man'} className="radio checked:bg-[#92EEC3]" {...register("geslacht")} />
                        <span className="label-text ms-1">Man</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input type="radio" id="vrouw" name="vrouw" value={'vrouw'} className="radio checked:bg-[#92EEC3]" {...register("geslacht")} />
                        <span className="label-text ms-1">Vrouw</span>
                      </label>
                    </div>
                  </div>

                  <div className="invalid-feedback h-3">
                    {errors.geslacht?.message}
                  </div>
                </div>

                <div className="relative mb-3">
                  <label htmlFor="huisNummer"> Huisnummer </label>
                  <HuisNummerPopOver />
                  <input
                    id="huisNummer"
                    name="huisNummer"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.huisNummer ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("huisNummer")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.huisNummer?.message}
                  </div>
                </div>


                <div className="relative mb-3">
                  <label htmlFor="postCode">Postcode</label>
                  <PostCodePopOver />
                  <input
                    id="postCode"
                    name="postCode"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.postCode ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("postCode")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.postCode?.message}
                  </div>
                </div>

                <div className="relative mb-3">
                  <label htmlFor="plaats">Plaats </label>
                  <PlaatsPopOver />
                  <input
                    id="plaats"
                    name="plaats"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.plaats ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("plaats")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.plaats?.message}
                  </div>
                </div>

                </div>





              {/* line 2  */}
                <div >
              


                <div className="relative mb-3">
                  <label htmlFor="straatNaam">Straatnaam </label>
                  <StraatNaamPopOver />
                  <input
                    id="straatNaam"
                    name="straatNaam"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.straatNaam ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("straatNaam")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.straatNaam?.message}
                  </div>
                </div>

                <div className="relative mb-3">
                  <label htmlFor="teleFoonNummer">Telefoonnummer</label>
                  <TelefoonNummerPopOver />
                  <input
                    id="teleFoonNummer"
                    name="teleFoonNummer"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.teleFoonNummer ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("teleFoonNummer")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.teleFoonNummer?.message}
                  </div>
                </div>


                <div className="relative mb-3">
                  <label htmlFor="email"> Email</label>
                  <EmailPopOver />
                  <input
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.email ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("email")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.email?.message}
                  </div>
                </div>

                <div className="relative mb-3">
                  <label htmlFor="datumOpdrachtBevestiging">Datum Opdracht Bevestiging </label>
                  <DatumOpdrachtBevestigingPopOver position="top-[35px] right-[5px]" />
                  <input
                    id="datumOpdrachtBevestiging"
                    name="datumOpdrachtBevestiging"
                    type="date"
                    autoComplete="new-password"
                    className={`${errors.datumOpdrachtBevestiging ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("datumOpdrachtBevestiging")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.datumOpdrachtBevestiging?.message}
                  </div>
                </div>


                <div className="relative mb-3">
                  <label htmlFor="Kenmerk"> Uw Kenmerk </label>
                  <KenmerkPopOver />
                  <input
                    id="Kenmerk"
                    name="Kenmerk"
                    type="text"
                    autoComplete="new-password"
                    className={`${errors.Kenmerk ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("Kenmerk")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.Kenmerk?.message}
                  </div>
                </div>


                <div className="relative mb-3">
                  <label htmlFor="middelCode"> Bedrijfsmiddelcode </label>
                  <BedrijfsMiddelCodePopOver position="top-[4px] right-[5px]" />

                  {middelCodes.length < 5 &&
                    <a onClick={createMiddleCode}
                      className={`max-w-[400px] absolute top-[4px] right-[35px] hover:text-gray-500`} >
                      <FeatherIcon icon="plus-circle" className="info-icon" />
                    </a>
                  }
                  {
                    middelCodes.map((code, index) => (
                      <div key={index} className="flex gap-2 items-center relative">
                        <input
                          id="middelCode"
                          name="middelCode"
                          type="text"
                          onInput={(e) => {
                            const value = e.target.value;
                            setMiddelCodes((prev) => prev.map((item, i) => i === index ? value : item));
                          }}
                          value={code}
                          autoComplete="new-password"
                          className={`appearance-none w-full py-2 px-3`}
                        />
                        {index !== 0 &&
                          <FeatherIcon icon="x-circle" className="h-[19px] text-red-700 cursor-pointer absolute right-[5px] top-[12px]" onClick={() => removeMiddleCode(index)} />
                        }
                      </div>
                    ))
                  }

                  {isMiddleCodeInvalid() && <div className="invalid-feedback h-3">
                    {
                      isSubmitted ? isMiddleCodeInvalid() : ''
                    }
                  </div>
                  }
                </div>


                <div className="relative mb-3">
                  <label htmlFor="teleFoonNummer">Kopie ondertekende offerte</label>
                  <KopieOndertekendeOffertePopOver position="top-[2px] right-[5px]" />
                  <div className="uploaded-files">
                    {uploadedFiles?.length > 0 && uploadedFiles.map((file, index) => (
                      <div key={index} className="flex justify-between items-center text-[#82A695] my-2 border-b-2 pb-1">
                        <div className="cursor-pointer" onClick={() => openUrlInNewTab(file?.url)}>{file?.name?.slice(0, 30)}</div>
                        <div className="cursor-pointer" onClick={() => { setUploadedFiles((prev) => prev.filter((item, i) => i !== index)) }}>
                          <FeatherIcon icon="x-circle" className="h-[19px] text-red-700 me-[5px]" />
                        </div>
                      </div>
                    ))}
                  </div>
                  {uploadedFiles?.length < 5 && (
                    <button disabled={isUploadLoading} type="button" onClick={selectAndUploadFile} className="btn upload-button mt-2 w-full">
                      {isUploadLoading ? <span className="loading loading-spinner"></span> : ''}
                      {isUploadLoading ? 'Uploaden, even geduld a.u.b...' : 'Een bestand uploaden.'}
                    </button>
                  )}
                  {!uploadedFiles?.length && isSubmitted && (
                    <div className="invalid-feedback h-3 mt-2">
                      Upload minimaal één bestand.
                    </div>
                  )}
                </div>


                <div className="relative mb-3">
                  <label htmlFor="aantekeningVoorOns"> Aantekening Voor Ons </label>
                  <AantekeningVoorOnsPopOver position="top-[4px] right-[5px]" />
                  <textarea
                    id="aantekeningVoorOns"
                    name="aantekeningVoorOns"
                    type="text"
                    rows={2}
                    autoComplete="new-password"
                    className={`${errors.aantekeningVoorOns ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                    {...register("aantekeningVoorOns")}
                  />
                  <div className="invalid-feedback h-3">
                    {errors.aantekeningVoorOns?.message}
                  </div>
                </div>


                </div>
              </div>
           
          

      
              <div className="mt-7 p-2 flex md:justify-end justify-center">
                {isUpdate ?
                  <Button btnClassNames={`btn-neutral w-min px-16`} text="Indienen" isLoading={isLoading} onClick={() => { handleSubmit(); setIsSubmitted(true); }} loadingText="indienen..." />
                  :
                  <Button btnClassNames={`btn-neutral w-min px-16`} text="Verstuur" isLoading={isLoading} onClick={() => { handleSubmit(); setIsSubmitted(true); }} loadingText="Verzending..." />
                }
              </div>
            </form>

            <div className="w-30 mt-10 md:p-10">
              <div className="details-card w-fit bg-[#F4F4F4] mx-auto py-6 px-8 rounded-[20px]">
                <p className="mb-5">Factuur voor <strong>{userData?.naam}</strong></p>
                <div>
                  <p>Bedrag exclusief BTW: <strong>€{
                    isUpdate ? formatNumber(updateAppPricing?.exclusiveBTW) : formatNumber(userData?.applicationFee)
                  }</strong></p>
                  <p>Bedrag inclusief BTW: <strong>€{
                    isUpdate ? formatNumber(updateAppPricing?.exclusiveBTW + updateAppPricing?.inclusiveBTW) : formatNumber(userData?.applicationFee + userData?.tax)
                  }</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientEIAAanvragenPage;
