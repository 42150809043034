import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APPLICATION_STATUS, RoutesFullPath } from "../../utils/constants";
import AdminRejectAanvraagModal from "../../components/reason-modal";
import { getApplicationById, adminUpdateApplicationStatus, fetchUserCompanyDetails } from "../../core/axios/endpoints";
import PageLoader from "../../components/page-loader";
import { toastrError, toastrSuccess } from "../../utils/alertify";
import KvkDetailsModal from "../../components/kvk-details-model";

const AdminAanvraagDetailsPage = (props) => {
    const [AanvraagDetails, setAanvraagDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isKvkModalOpen, setIsKvkModalOpen] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const [reason, setReason] = useState("");
    const { aanraagId } = useParams();
    const navigate = useNavigate();

    const getAanraagDetails = async () => {
        setLoading(true);
        try {
            const data = await getApplicationById(aanraagId);
            setAanvraagDetails(data);
        } catch (err) {
            setError(true);
            if (err?.isNetworkError) {
                setIsNetworkError(true);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (aanraagId) {
            getAanraagDetails();
        }
    }, [aanraagId, isReload]);

    const closeDropdown = async (value, reason) => {
        if (value) {
            if (value === APPLICATION_STATUS.AFGEKEURD && !reason) {
                setIsModalOpen(true);
                return;
            }
        }
        await updateStatus(value, reason);
        document.getElementById("dropdown-status")?.removeAttribute("open");
    };

    const updateStatus = async (value, reason) => {
        try {
            setLoading(true);
            const updates = { status: value };
            if (value === APPLICATION_STATUS.AFGEKEURD && !reason) {
                return;
            }
            if (reason) {
                updates.afgekeurdReason = reason;
            }
            const res = await adminUpdateApplicationStatus(aanraagId, updates);
            navigate(-1);
            toastrSuccess(res?.message || "Status updated successfully");
        } catch (err) {
            console.error("Error updating application status:", err);
            toastrError(err?.response?.data?.error || "Error updating application status");
        } finally {
            setLoading(false);
        }
    }

    const isStatusEditable = AanvraagDetails?.status !== APPLICATION_STATUS.AFGEKEURD && AanvraagDetails?.status !== APPLICATION_STATUS.GOEDGEKEURD;

    const formatNumber = (number) => {
        return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const getKvkData = async () => {
        setLoading(true);
        await fetchUserCompanyDetails(AanvraagDetails?.KvkNummer).then(data => {
            setLoading(false);
            setIsReload(!isReload);
        }).catch(err => {
          toastrError(err?.response?.data?.error || "Er is een fout opgetreden. Probeer het later opnieuw.");
          setLoading(false);

        }).finally(() => {
           
        });
      }

      function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
      }

      
    if (loading) {
        return <PageLoader />;
    }

    if (isError) {
        return (
            <div className="main-wrapper">
                <div className="error-box">
                    <h1>{isNetworkError ? "503" : "404"}</h1>
                    <h3 className="h2 mb-3">
                        <i className="fas fa-exclamation-circle" /> Oops! {isNetworkError ? "Network Error!" : ""}
                    </h3>
                    <p className="h4 font-weight-normal mt-2">
                        {isNetworkError ? "Network Error" : "Er is iets misgegaan. Probeer het later opnieuw"}
                    </p>
                    <button onClick={() => navigate(-1)} className="btn customized-btn mt-2 animate-none bg-[#A5A5A5] text-white border-none px-7 shadow-none py-3 rounded-[5px]">
                        Ga terug
                    </button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="content aanvraag-details-page">
                    <div className="grid md:grid-cols-3 grid-cols-1 mt-3 card bg-[#F4F4F4] px-10 py-4 rounded-[20px]">
                        {
                            isStatusEditable && <div >
                                <p>
                                    <strong>Status van aanvraag</strong>
                                </p>
                                <details className="dropdown-action dropdown" id="dropdown-status">
                                    <summary className="btn m-0 p-0 font-normal h-fit min-h-fit hover:bg-transparent border-none hover:border-none hover:outline-none">
                                        {
                                            AanvraagDetails?.status == APPLICATION_STATUS.INGEDIEND ? 'Ingediend bij RVO' : AanvraagDetails?.status
                                        }
                                        <FeatherIcon icon="chevron-down" className="h-4"></FeatherIcon>
                                    </summary>
                                    <ul className="dropdown-content z-[1] flex flex-col gap-1 w-[200px]">
                                        <a className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1" onClick={() => { closeDropdown(APPLICATION_STATUS.GOEDGEKEURD) }}>{APPLICATION_STATUS.GOEDGEKEURD}</a>
                                        <a className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1" onClick={() => { closeDropdown(APPLICATION_STATUS.INGEDIEND) }}>
                                            Ingediend bij RVO
                                        </a>
                                        <a className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1" onClick={() => { closeDropdown(APPLICATION_STATUS.AFGEKEURD) }}>{APPLICATION_STATUS.AFGEKEURD}</a>
                                    </ul>
                                </details>
                            </div>}

                        {
                            !isStatusEditable && <div>
                                <p><strong>Status van aanvraag</strong></p>
                                <p>{AanvraagDetails?.status}</p>
                            </div>
                        }
                        <div>
                            <p>
                                <strong>Kenmerk klant</strong>
                            </p>
                            <p>{AanvraagDetails?.Kenmerk}</p>
                        </div>

                        <div>
                            <p>
                                <strong>Klantnaam</strong>
                            </p>
                            <p>{AanvraagDetails?.VoorLetters + " " + AanvraagDetails?.achterNaam}</p>
                        </div>
                    </div>

                    {AanvraagDetails?.status == APPLICATION_STATUS.AFGEKEURD && <div className="grid md:grid-cols-1 grid-cols-1 mt-1 card bg-[#F4F4F4] px-10 py-4 rounded-[20px] text-center">
                        <div>
                            <p>
                                <strong>Reden voor Afgekeurdt</strong>
                            </p>
                            <p className="text-red-600 mb-2">{AanvraagDetails?.afgekeurdReason}</p>

                        </div>

                    </div>}

                    <div className="grid md:grid-cols-2 grid-cols-1 mt-10 md:gap-[50px]">
                        <div className="card card-type-2 bg-[#F4F4F4] px-10 py-6 rounded-[20px]">
                            <p className="mb-5 title"><strong>Persoonlijke gegevens</strong></p>
                            <div className="flex justify-between items-start">
                                <div className="left-items">
                                    <div>
                                        <p><strong>Naam</strong></p>
                                        <p>{
                                            AanvraagDetails?.VoorLetters + " " + AanvraagDetails?.achterNaam
                                        }</p>
                                    </div>
                                    <div>
                                        <p><strong>Geslacht</strong></p>
                                        <p>{AanvraagDetails?.geslacht}</p>
                                    </div>
                                    <div>
                                        <p><strong>Postcode</strong></p>
                                        <p>{AanvraagDetails?.postCode}</p>
                                    </div>

                                    <div>
                                        <p><strong>Woonplaats</strong></p>
                                        <p>{AanvraagDetails?.plaats}</p>
                                    </div>



                                    <div>
                                        <p><strong>Adres</strong></p>
                                        <p>{AanvraagDetails?.straatNaam} {AanvraagDetails?.huisNummer}</p>
                                    </div>

                                   
                                { AanvraagDetails?.isKvkDetailsAvailable &&  
                                 <button
                                  onClick={() => setIsKvkModalOpen(true)}
                                  className="btn animate-none bg-[#DEF8EC] text-[#82A695] border-none hover:bg-[#DEF8EC] hover:text-[#82A695] px-4 shadow-none py-1 mt-1 w-[227px]"
                                 >
                                 <FeatherIcon icon="eye" className="h-5" /> View Kvk Details
                                </button>
                                }
                                {
                                    !AanvraagDetails?.isKvkDetailsAvailable && <p className="text-red-600 mb-2">Kvk details not available</p>
                                }
                                {
                                   !AanvraagDetails?.isKvkDetailsAvailable && <button
                                    onClick={() => getKvkData()}
                                    className="btn animate-none bg-[#DEF8EC] text-[#82A695] border-none hover:bg-[#DEF8EC] hover:text-[#82A695] px-4 shadow-none py-1 mt-1 w-[227px]"
                                   >
                                   <FeatherIcon icon="arrow-down-circle" className="h-5" /> Get Kvk Details
                                  </button>
                                }
                              
                                </div>
                                <div className="right-items">
                                    <div>
                                        <p><strong>Telefoonnummer</strong></p>
                                        <p>{AanvraagDetails?.teleFoonNummer}</p>
                                    </div>
                                    <div>
                                        <p><strong>E-mail</strong></p>
                                        <p>{AanvraagDetails?.email}</p>
                                    </div>
                                    <div>
                                        <p><strong>KVK-nummer</strong></p>
                                        <p>{AanvraagDetails?.KvkNummer}</p>
                                    </div>
                                    <div>
                                        <p><strong>Handelsnaam</strong></p>
                                        <p>{AanvraagDetails?.handelsNaam}</p>
                                    </div>

                                    {
                                        ["EIA", "EIA Zon"].includes(AanvraagDetails?.regling) && <div>
                                        <p><strong>Bedrijfsmiddelcodes</strong></p>
                                        {
                                            AanvraagDetails?.bedrijfsmiddelCode?.map((item, index) => {
                                                return <p key={index}>{item}</p>
                                            })
                                        }
                                        
                                            
                                    </div>
                                    }

                                   {
                                    AanvraagDetails?.regling === "ISDE" && <div>
                                        <p><strong>meldCodes</strong></p>
                                        {
                                            AanvraagDetails?.meldCodes?.map((item, index) => {
                                                return <p key={index}>{item}</p>
                                            })
                                        }
                                        
                                            
                                    </div>
                                    }
                                </div>


                              
                            </div>
                             
                        </div>

                        <div className="card card-type-2 bg-[#F4F4F4] px-10 py-6 rounded-[20px]">
                            <p className="mb-5 title">
                                <strong>Subsidie ({AanvraagDetails?.regling})</strong>
                            </p>
                            <div className="flex justify-start h-full">
                                <div className="left-items flex flex-col justify-between h-full">
                                    <div>
                                        {AanvraagDetails?.regling === "EIA Zon" && (
                                            <>
                                                <div>
                                                <p><strong>Datum opdrachtbevestiging</strong></p>
                                                <p>{ formatDate(AanvraagDetails?.datumOpdrachtBevestiging) }</p>
                                                </div>
                                                <div>
                                                    <p><strong>Aantal zonnepanelen</strong></p>
                                                    <p>{AanvraagDetails?.aantalZonnepanelen}</p>
                                                </div>
                                                <div>
                                                    <p><strong>Totaal vermogen (Wp)</strong></p>
                                                    <p>{AanvraagDetails?.totaalVermogen}</p>
                                                </div>
                                            </>
                                        )}
                                        {["EIA", "ISDE"].includes(AanvraagDetails?.regling) && (
                                            <div>
                                                  <p><strong>Datum opdrachtbevestiging</strong></p>
                                                  <p>{ formatDate(AanvraagDetails?.datumOpdrachtBevestiging) }</p>
                                            </div>
                                        )}
                                        {AanvraagDetails?.regling === "ISDE" && (
                                            <div>
                                                <p><strong>Type</strong></p>
                                                <p>{AanvraagDetails?.type}</p>
                                            </div>
                                        )}

                                        <div>
                                            { AanvraagDetails?.regling === "ISDE" && AanvraagDetails?.type === 'warmtepomp' && <a
                                                href={AanvraagDetails?.annualStatement}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn animate-none bg-[#DEF8EC] text-[#82A695] border-none hover:bg-[#DEF8EC] hover:text-[#82A695] px-4 shadow-none w-[150px]"
                                            >
                                                <FeatherIcon icon="download" className="h-5" />
                                                Jaarnota
                                            </a>
                                            }
                                        </div>

                                    </div>
                                    {
                                       !!AanvraagDetails?.quotationCopyFiles?.length && 
                                      <div>
                                      <p>Offerte</p>
                                    <div className="flex flex-row gap-2 flex-wrap justify-center">
                                        {AanvraagDetails?.quotationCopyFiles?.map((url, index) => {
                                            const fileName = url.split('/').pop();
                                            return <a
                                                key={index}
                                                href={url} // Replace with the actual base URL or path where your files are stored
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn animate-none bg-[#DEF8EC] text-[#82A695] border-none hover:bg-[#DEF8EC] hover:text-[#82A695] px-4 shadow-none py-1 mt-1 w-[227px]"
                                            >
                                                <FeatherIcon icon="download" className="h-5" /> {fileName?.length > 20 ? fileName?.slice(0, 15) + "..." : fileName}
                                            </a>
                                        })}
                                    </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 mt-5 md:gap-[50px]">
                        <div className="card card-type-2 card-type-3 bg-[#F4F4F4] px-10 py-6 rounded-[20px]">
                            <p className="mb-5 title">
                                <strong>Aantekeningen (Intern)</strong>
                            </p>
                            <div className="flex justify-between items-start">
                                <div className="left-items">
                                    <div>
                                        <p><strong>{AanvraagDetails?.aantekeningVoorOns || "Geen aantekingen"}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-type-2 card-type-3 bg-[#F4F4F4] px-10 py-6 rounded-[20px]">
                            <p className="mb-5 title">
                                <strong>Factuurinformatie</strong>
                            </p>
                            <div className="flex justify-between items-start">
                                <div>
                                    <p>Factuur aan: <strong>{AanvraagDetails?.klantId?.naam}</strong></p>
                                    <p>Bedrag exclusief BTW: <strong>€{formatNumber(AanvraagDetails?.exclusiveBTW || '0')}</strong></p>
                                    <p>Bedrag inclusief BTW: <strong>€{formatNumber(AanvraagDetails?.totaal || '0')}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdminRejectAanvraagModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={(res) => { res ? closeDropdown(APPLICATION_STATUS.AFGEKEURD, res) : null }} />
                    <KvkDetailsModal isOpen={isKvkModalOpen}  onClose={() => setIsKvkModalOpen(false)} kvkDetails={AanvraagDetails?.kvkDetails} />
                </div>
            </div>
        </>
    );
};

export default AdminAanvraagDetailsPage;
