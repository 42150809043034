import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Modal from "../../components/profile-modal";
import { Logo } from "../../core/path/images";
import { validateCompleteProfileToken } from "../../core/axios/endpoints";
import { NETWORK_ERROR } from "../../utils/constants";
import PageLoader from "../../components/page-loader";
const CompleteProfilePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const {token} = useParams();
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    validateToken();
  }, [location]);


  const validateToken = async () => {
    try {
      await validateCompleteProfileToken({ token });
      setIsModalOpen(true);
    } catch (err) {
      setError(true);
      if(err?.isNetworkError){
        setIsNetworkError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }


  if (isLoading) {
    return <PageLoader />;
}

if (isError) {
    return (
        <div className="main-wrapper">
            <div className="error-box">
                <h1>{isNetworkError ? "503" : "404" }</h1>
                <h3 className="h2 mb-3">
                    <i className="fas fa-exclamation-circle" /> {isNetworkError ? "Network Error!" : " Invalid or Expired Link!"}
                </h3>
                <p className="h4 font-weight-normal mt-2">
                    {isNetworkError ? NETWORK_ERROR : "you link has been expired or invalid, please contact your admin."}
                </p>
               
            </div>
        </div>
    );
}


  return (
    <div className="main-wrapper flex flex-col items-center justify-center min-h-screen bg-[#F4F0EC]">
      <div className="mb-8">
        <img src={Logo} alt="Logo"  />
      </div>
      <h1 className="text-3xl font-bold mb-4 text-[#92EEC3]">Complete Your Profile</h1>
      <p className="text-lg text-center mb-8 text-[#A5A5A5]">
        Please complete your profile information to proceed.
      </p>
      <button
        onClick={() => setIsModalOpen(true)}
        className="btn bg-[#92EEC3] shadow-none border-none outline-none hover:bg-[#92EEC3] hover:border-none hover:shadow-none hover:outline-none text-white font-medium py-2 px-4 rounded"
      >
        Complete Now
      </button>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}  isCompleteProfile={true} token={token} />
      )}
    </div>
  );
};

export default CompleteProfilePage;
