import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { UserProvider } from '../core/context/user';
import SignInPage from './signIn';
import Error404 from './errorPages/Error404';
import Error500 from './errorPages/Error500';
import DefaultLayout from './layout/index';
import CompleteProfilePage from './customer/completeProfile';
import ForgetPasswordPage from './forget-password';
import ResetPasswordPage from './reset-password';

export default function App() {
  const location = useLocation();
  document.documentElement.setAttribute('data-theme', 'light');
  return (
    <UserProvider>
      <Routes>
        {/* Redirect from root path */}
        <Route path="/" element={<Navigate to="/signIn" />} />
        
        {/* Authentication and Error Routes */}
        <Route path="/signIn" element={<SignInPage />} />
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/complete-profile/:token" element={<CompleteProfilePage />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-500" element={<Error500 />} />
        
        {/* Protected Routes */}
        <Route path="/panel/*" element={<DefaultLayout />} />
        
        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Navigate to="/error-404" replace />} />
      </Routes>
    </UserProvider>
  );
}
