import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import SearchComponent from "../../components/search";
import Table from '../../components/datatable/datatable';
import CreateUserModal from "../../components/create-customer-modal";
import ViewCustomerProfileAdmin from "../../components/view-customer-admin";
import Dropdown from "../../components/dropdown";
import { adminGetAllUsers, changeCustomerStatus, deleteCustomers, updateCustomerTax, updateProfile, updateUserApiLimits } from "../../core/axios/endpoints";
import Papa from "papaparse";
import Swal from "sweetalert2";
import AdminUpdateCustomerTaxModal from "../../components/update-tax-model";
import AdminUpdateUserLimitsModal from "../../components/update-api-limits";

const AdminKlantenPage = (props) => {
    const [data, setData] = useState([]);
    const [userCounts, setUserCounts] = useState({
        allUsers: 0,
        activeUsers: 0,
        inactiveUsers: 0,
    });
    const [loading, setLoading] = useState(false);
    const [isEditOpen, SetisEditOpen] = useState(false);
    const [SelectedRows, setSeletedRows] = useState([]);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const navigate = useNavigate();
    const [isUpdateCostModalOpen, setIsUpdateCostModalOpen] = useState(false);
    const [applicationFee, setApplicationFee] = useState(0);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [isUpdateLimitsModalOpen, setIsUpdateLimitsModalOpen] = useState(false);
    const [rateLimitHour, setRateLimitHour] = useState(5);
    const [rateLimitDay, setRateLimitDay] = useState(100);
    const closeDropdown = (record) => {
        document.getElementById(`dropdown-klanten-action-${record?.id}`)?.removeAttribute("open");
    }

    const RowChanges = (rows) => {
        setSeletedRows(rows)
    }

    const columns = [
        {
            title: "Naam",
            dataIndex: "naam",
            sorter: (a, b) => a.naam.localeCompare(b.naam),
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: "Bedrijf",
            dataIndex: "bedrijfsnaam",
            sorter: (a, b) => a.bedrijfsnaam.localeCompare(b.bedrijfsnaam),
        },
        {
            title: "Telefoonnummer",
            dataIndex: "telefoonnummer",
            sorter: (a, b) => a.telefoonnummer.localeCompare(b.telefoonnummer),
        },
        {
            title: "Profiel Voltooid",
            dataIndex: "isProfileCompleted",
            render: (record) => (
                <span>{record ? "Ja" : "Nee"}</span>
            )
        },
        {
            title: "Totaal Api",
            dataIndex: "overallRequestCount"
        },
        {
            title: "Dag Api",
            dataIndex: "apiRequestCountDay"
        },
        {
            title: "Uur Api",
            dataIndex: "apiRequestCountHour"
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: "Action",
            render: (record) => (
                <Dropdown className="dropdown-action dropdown dropdown-end" id={"dropdown-klanten-action-" + record?.id}>
                    <summary className="inline-flex items-center mt-2 me-2 p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50 ">
                        <FeatherIcon icon="more-vertical" />
                    </summary>
                    <ul className="dropdown-content z-[1] flex flex-col gap-1 w-fit">
                        <Link onClick={() => { setUserDetails(record); closeDropdown(record); setIsViewModalOpen(true) }} className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1">
                            View
                        </Link>
                        <Link onClick={() => {
                            closeDropdown(record);
                            handleUserStatusUpdate(record._id, record.status === "active" ? "inactive" : "active");
                        }
                        } className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1 text-red-400 hover:text-black hover:text-opacity-75">
                            Markeer {record.status === "active" ? "Inactief" : "Actief"}
                        </Link>
                        <Link onClick={() => {
                            closeDropdown(record);
                            openUpdateModal(record);
                        }
                        } className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1 text-red-400 hover:text-black hover:text-opacity-75">
                            Update Kosten
                        </Link>
                        <Link onClick={() => { closeDropdown(record); openUpdateLimitsModal(record); }} className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1 text-red-400 hover:text-black hover:text-opacity-75">
                            Update Limits
                        </Link>
                    </ul>
                </Dropdown>
            ),
        },
    ];

    const fetchData = async ({ page, pageSize, resetPageSize }) => {
        try {
            setLoading(true);
            const response = await adminGetAllUsers(page, pageSize, searchText);
            const result = {
                data: response.docs.map((user, index) => ({ ...user, key: index })),
                totalDataCount: response.totalDocs,
                resetPageSize,
            };
            setUserCounts(response.userCounts);
            setData(result);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData({ page: 1, pageSize: 10, resetPageSize: false });
    }, [searchText]);

    const handleSearch = (query) => {
        setSearchText(query);
    };

    const handleDelete = async () => {
        Swal.fire({
            title: 'Weet je het zeker?',
            text: "Dit kan niet ongedaan worden gemaakt!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ja, verwijder het!',
            cancelButtonText: 'Annuleren'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteCustomers(SelectedRows);
                    fetchData({ page: 1, pageSize: 10, resetPageSize: false });
                    Swal.fire(
                        'Verwijderd!',
                        'De geselecteerde klanten zijn verwijderd.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting customers:", error);
                    Swal.fire(
                        'Mislukt!',
                        'Er is een fout opgetreden bij het verwijderen van de klanten.',
                        'error'
                    );
                }
            }
        });
    };

    const handleUserStatusUpdate = async (id, status) => {
        Swal.fire({
            title: 'Weet je het zeker?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ja, bijgewerkt het!',
            cancelButtonText: 'Annuleren'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await changeCustomerStatus(id, { status });
                    fetchData({ page: 1, pageSize: 10, resetPageSize: false });
                    Swal.fire(
                        'Bijgewerkt!',
                        'De geselecteerde klanten zijn Bijgewerkt.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting customers:", error);
                    Swal.fire(
                        'Mislukt!',
                        'Er is een fout opgetreden bij het bijgewerkt van de klanten.',
                        'error'
                    );
                }
            }
        });
    };

    const handleExport = () => {
        const csvData = data.data.map(row => ({
            ID: row._id,
            Naam: row.naam,
            Email: row.email,
            Telefoonnummer: row.telefoonnummer,
            Status: row.status,
            Rol: row.role,
            ApplicationFee: row.applicationFee,
            Tax: row.tax,
            IsProfileCompleted: row.isProfileCompleted,
            CreatedAt: new Date(row.createdAt).toLocaleString(),
            UpdatedAt: new Date(row.updatedAt).toLocaleString(),
            Adres: row.adres,
            Bedrijfsnaam: row.bedrijfsnaam,
            Plaats: row.plaats,
            Postcode: row.postcode,
            ProfielFoto: row.profielFoto,
            ApiHits: row.overallRequestCount,
        }));
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "klanten.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const openUpdateModal = async (record) => {
        setApplicationFee(record?.applicationFee || 0);
        setSelectedCustomerId(record._id);
        setIsUpdateCostModalOpen(true);
    };

    const openUpdateLimitsModal = async (record) => {
        setRateLimitHour(record?.rateLimitHour);
        setRateLimitDay(record?.rateLimitDay);
        setSelectedCustomerId(record._id);
        setIsUpdateLimitsModalOpen(true);
    };

    const UpdateCustomerTax = async (applicationFee) => {
        try {
            await updateCustomerTax(selectedCustomerId, {
                applicationFee,
                tax: applicationFee * 0.21
            });
            fetchData({ page: 1, pageSize: 10, resetPageSize: false });
            Swal.fire(
                'Bijgewerkt!',
                'De klanten kosten zijn bijgewerkt.',
                'success'
            );
        } catch (error) {
            console.error("Error updating customer tax:", error);
            Swal.fire(
                'Mislukt!',
                'Er is een fout opgetreden bij het bijwerken van de klanten kosten.',
                'error'
            );
        }
    }


    const handleSubmitLimits = async (limits) => {
        try {
            await updateUserApiLimits(selectedCustomerId, limits);
            fetchData({ page: 1, pageSize: 10, resetPageSize: false });
            Swal.fire(
                'Updated!',
                'The user rate limits have been updated.',
                'success'
            );
        } catch (error) {
            console.error("Error updating user rate limits:", error);
            Swal.fire(
                'Failed!',
                'An error occurred while updating the user rate limits.',
                'error'
            );
        }
    };

    return (
        <div className="page-wrapper">
            <div className="content admin-pages">
                <div className="py-4">
                    <div className="grid md:grid-cols-4 grid-cols-1 mt-5 md:gap-[30px]">
                        <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                            <p className="title text-[16px]">Actieve klanten</p>
                            <div className="text-with-icon flex items-center gap-2 mt-3">
                                <div className="icon bg-[#DDF9E4]">
                                    <FeatherIcon icon="user" size="20" />
                                </div>
                                <p className="count text-[16px]">{userCounts.activeUsers}</p>
                            </div>
                        </div>

                        <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                            <p className="title text-[16px]">Inactieve klanten</p>
                            <div className="text-with-icon flex items-center gap-2 mt-3">
                                <div className="icon bg-[#FFE4E4]">
                                    <FeatherIcon icon="user" size="20" />
                                </div>
                                <p className="count text-[16px]">{userCounts.inactiveUsers}</p>
                            </div>
                        </div>

                        <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                            <p className="title text-[16px]">Alle klanten</p>
                            <div className="text-with-icon flex items-center gap-2 mt-3">
                                <div className="icon bg-[#E4F0FF]">
                                    <FeatherIcon icon="user" size="20" />
                                </div>
                                <p className="count text-[16px]">{userCounts.allUsers}</p>
                            </div>
                        </div>

                        <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                            <p className="title text-[16px]">Overall Api Hits</p>
                            <div className="text-with-icon flex items-center gap-2 mt-3">
                                <div className="icon bg-[#E4F0FF]">
                                    <FeatherIcon icon="arrow-down-circle" size="20" />
                                </div>
                                <p className="count text-[16px]">{userCounts.overallRequestCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-10">
                    <SearchComponent onSearch={handleSearch} type={'users'} />
                </div>
                <div className="table-responsive card p-4 bg-[#FAFBFE]">
                    <div className="grid md:grid-cols-2 md:gap-0 gap-2 bg-[#FAFBFE] px-0 py-3">
                        <div className="flex md:justify-start justify-between items-center gap-2 flex-row">
                            <button disabled={!SelectedRows?.length} className="btn animate-none bg-[#EB4747] bg-opacity-20 text-black border-none hover:bg-opacity-30 hover:bg-[#EB4747] px-4 shadow-none py-1 disabled:bg-opacity-30 disabled:opacity-50 disabled:text-black disabled:bg-[#EB4747]" onClick={handleDelete}>
                                <FeatherIcon icon="trash-2" className="h-5 text-[#EB4747]" />
                                Verwijder
                            </button>

                            <button disabled={!SelectedRows?.length} className="btn animate-none bg-[#DEF8EC] text-black border-none hover:bg-[#DEF8EC] px-4 shadow-none py-1 disabled:opacity-50 disabled:text-black disabled:bg-[#DEF8EC]" onClick={handleExport}>
                                <FeatherIcon icon="download" className="h-5 text-[#82A695]" />
                                Exporteer
                            </button>
                        </div>

                        <div className="flex md:justify-end justify-center items-center gap-2 flex-row w-100">
                            <button onClick={() => setIsCreateModalOpen(true)} className="btn animate-none bg-[#DEF8EC] text-black border-none hover:bg-[#DEF8EC] px-4 shadow-none py-1 disabled:opacity-50 disabled:text-black disabled:bg-[#DEF8EC]">
                                <FeatherIcon icon="plus" className="h-5 text-[#82A695]" />
                                Klant registreren
                            </button>
                        </div>
                    </div>
                    <div className="bg-white rounded-[20px]">
                        <Table
                            columns={columns}
                            response={data}
                            fetchData={fetchData}
                            SelectedRows={RowChanges}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
            <CreateUserModal isOpen={isCreateModalOpen} onClose={() => { setIsCreateModalOpen(false); fetchData({ page: 1, pageSize: 10 }) }} />
            <ViewCustomerProfileAdmin isOpen={isViewModalOpen} onClose={() => setIsViewModalOpen(false)} userDetails={userDetails} />
            <AdminUpdateCustomerTaxModal isOpen={isUpdateCostModalOpen} onSubmit={UpdateCustomerTax} applicationfee={applicationFee} onClose={() => { setIsUpdateCostModalOpen(false); fetchData({ page: 1, pageSize: 10 }) }} />
            <AdminUpdateUserLimitsModal
                isOpen={isUpdateLimitsModalOpen}
                onClose={() => { setIsUpdateLimitsModalOpen(false); fetchData({ page: 1, pageSize: 10, resetPageSize: false }); }}
                onSubmit={handleSubmitLimits}
                rateLimitHour={rateLimitHour}
                rateLimitDay={rateLimitDay}
            />
        </div>
    );
};

export default AdminKlantenPage;
