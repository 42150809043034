import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../core/context/user';

const ProtectedRoute = ({ children }) => {
  const { userData } = useUser();
  const location = useLocation();

  if (!userData) {
    return <Navigate to="/signIn" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
