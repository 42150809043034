import React, { useEffect, useState } from "react";
import Button from "./button";

const AdminUpdateCustomerTaxModal = ({ isOpen, onClose, onSubmit, applicationfee }) => {
  const [applicationFee, setApplicationFee] = useState(0);
  const [error, setError] = useState("");
  useEffect(() => {
    const modal = document.getElementById("update-fees-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }



  useEffect(() => {
    setApplicationFee(applicationfee);
  }, [applicationfee]);

  const submitForm = () => {
    if (!applicationFee) {
      setError("Aanvraagkosten mogen niet leeg zijn");
      return;
    }
    if(applicationFee < 0){
      setError("Vul een geldig bedrag in");
      return;
    }
    setError("");
    onSubmit(applicationFee);
    handleClose();
  };



  return (
    <dialog id="update-fees-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box">

        <div className="modal-content ">

        <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>
        <h1 className="modal-title text-center">
          <strong>Update Klant Kosten</strong>
        </h1>
        <form
          className="update-profile-form mt-5"
          
        >
          <div className="grid md:grid-cols-1 gap-10 mt-3 grid-cols-1">
            <div>
            <div className="grid md:grid-cols-1 gap-10 mt-3 grid-cols-1">
            <div>
              <input
                id="applicationFee"
                name="applicationFee"
                type="number"
                placeholder="Voer de kosten in"
                className={`appearance-none w-full py-2 px-3 dark:bg-white`}
                value={applicationFee || ''}
                onChange={(e) => setApplicationFee(Number(e.target.value))}
              />
              <div className="invalid-feedback h-3 mb-4">
                {error}
              </div>
            </div>
          </div>
            </div>
          </div>
        
          <div className="py-2 flex md:justify-end justify-center">
            <Button btnClassNames={`btn-neutral w-min px-4`} text={'Update Kosten'} type={'button'} onClick={()=>submitForm()} />
          </div>
        </form>
        
        </div>
      </div>
    </dialog>
  );
};

export default AdminUpdateCustomerTaxModal;
