
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
import { useUser } from "../../core/context/user";
const Sidebar = (props) => {

  const { isCurrentUserAdmin } = useUser();

  // const { getUserRoles } = useUser();

  const location = useLocation();
  let pathname = location.pathname;

  useEffect(() => {
    document.querySelector(".main-wrapper").classList.remove("slide-nav");
    document.querySelector(".sidebar-overlay").classList.remove("opened");
    document.querySelector(".sidebar-overlay").onclick = function () {
      this.classList.remove("opened");
      document.querySelector(".main-wrapper").classList.remove("slide-nav");
    };
  }, [pathname]);


  return (
    <>
      { isCurrentUserAdmin() ?
        <div
        className={`sidebar index-4`}id="sidebar">
        <div className="slimScrollDiv">
          <div className="sidebar-inner slimscroll">
            <div
              id="sidebar-menu"
              className="sidebar-menu"
            >
              <ul className="lg:mt-[50px]">
                <li className="submenu-open">
                  <ul>
                      <li
                        className={pathname.includes("dashboard") ? "active" : ""}
                      >
                        <Link to="/panel/admin/dashboard" >
                          <span>Dashboard</span>
                        </Link>
                      </li>
                      <li
                      className={pathname.includes("klanten") ? "active" : ""}
                      >
                        <Link
                         
                          to="/panel/admin/klanten"
                        >
                          <span>Klanten</span>
                        </Link>
                      </li>
                      <li
                        className={pathname.includes("eia-overzicht") ? "active" : ""}
                      >
                        <Link
                          to="/panel/admin/eia-overzicht"
                        >
                          <span>EIA Overzicht</span>
                        </Link>
                      </li>
                      <li
                        className={ pathname.includes("eia-zon-overzicht") ? "active" : ""}
                      >
                        <Link
                          to="/panel/admin/eia-zon-overzicht"
                        >
                          <span>EIA Zon Overzicht</span>
                        </Link>
                      </li>

                      <li
                        className={ pathname.includes("isde-overzicht") ? "active" : ""}
                      >
                        <Link
                          to="/panel/admin/isde-overzicht"
                        >
                          <span>ISDE Overzicht</span>
                        </Link>
                      </li>

                      <li
                        className={ pathname.includes("financieel") ? "active" : ""}
                      >
                        <Link
                          to="/panel/admin/financieel"
                        >
                          <span>Financieel</span>
                        </Link>
                      </li>


                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
        :
        <div
        className={`sidebar index-4 ${pathname.includes("/index-three") ? "d-none" : ""
          }`}
        id="sidebar"
      >

        <div className="slimScrollDiv">
          <div className="sidebar-inner slimscroll">
            <div
              id="sidebar-menu"
              className="sidebar-menu"
            >
              <ul className="lg:mt-[50px]">
                <li className="submenu-open">
                  <ul>
                    {(
                      <li
                        className={
                          pathname.includes("dashboard") ? "active" : ""
                        }
                      >
                        <Link to="/panel/customer/dashboard" >
                          <span>Dashboard</span>
                        </Link>
                      </li>
                    )}
                    {(
                      <li
                      className={
                        pathname.includes("eia-aanvragen") ? "active" : ""
                      }
                      >
                        <Link
                         
                          to="/panel/customer/eia-aanvragen"
                        >
                          <span>EIA Aanvragen</span>
                        </Link>
                      </li>
                    )}

                    {(
                      <li
                        className={pathname.includes("eia-zon-aanvragen") ? "active" : ""}
                      >
                        <Link
                          to="/panel/customer/eia-zon-aanvragen"
                        >
                          <span>EIA Zon Aanvragen</span>
                        </Link>
                      </li>
                    )}

                    {(
                      <li
                        className={ pathname.includes("isde-aanvragen") ? "active" : ""}
                      >
                        <Link
                          to="/panel/customer/isde-aanvragen"
                        >
                          <span>ISDE Aanvragen</span>
                        </Link>
                      </li>
                    )}



                    {(
                      <li
                        className={ pathname.includes("facturen") ? "active" : ""}
                      >
                        <Link
                          to="/panel/customer/facturen"
                        >
                          <span>Facturen</span>
                        </Link>
                      </li>
                    )}


                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      }
    </>
  );
};

export default Sidebar;
