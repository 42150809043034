import axios from 'axios';
import { toastrError } from '../../utils/alertify';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
});


const updateToken = async () => {
  const getToken = () => localStorage.getItem('accessToken');
  const AccessToken = getToken();
  if (AccessToken) {
    instance.defaults.headers['Authorization'] = `Bearer ${AccessToken}`;
  }
};

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      toastrError('Network error: Please check your internet connection.')
      // Network error (no response received)
      return Promise.reject({
        isNetworkError: true,
        message: 'Network error: Please check your internet connection.'
      });
    } else {
      // Other errors
      return Promise.reject(error);
    }
  }
);

export { instance, updateToken };
