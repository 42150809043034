
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Logo } from "../core/path/images";
import FeatherIcon from "feather-icons-react";
import {  Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../core/context/user";
import { toastrError, toastrSuccess } from "../utils/alertify";
import { ErrorsAndMessages, NETWORK_ERROR } from "../utils/constants";
import { resetPassword, validateResetToken } from "../core/axios/endpoints";
import PageLoader from "../components/page-loader";



const ResetPasswordPage = (props) => {
  const [eye, seteye] = useState(false);
  const { updateUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [isError, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useParams();
  

  useEffect(() => {
    validateToken();
  }, [location]);


  const validateToken = async () => {
    setIsLoading(true);
    try {
      await validateResetToken({ token });
    } catch (err) {
      setError(true);
      if(err?.isNetworkError){
        setIsNetworkError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }


  const validationSchema = Yup.object().shape({
    wachtwoord: Yup.string()
        .required("Wachtwoord is verplicht")
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Het wachtwoord moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten"),
   bevestigWachtwoord: Yup.string().oneOf(
        [Yup.ref("wachtwoord"), null],
        "Wachtwoord moet overeenkomen"
      )
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });



  const submitForm = (data) => {
    setIsLoading(true);
    resetPassword({
      wachtwoord: data.wachtwoord,
      token: token
    }).then((data) => {
      navigate("/signIn");
      toastrSuccess(data?.message ||  ErrorsAndMessages.passwordResetSuccess );
    }).catch((err) => {
      toastrError(err.response?.data?.error || ErrorsAndMessages.InvalidCredentials);
    }).finally(() => {
      setIsLoading(false);
    })
  }


  if (isLoading) {
    return <PageLoader />;
}

if (isError) {
    return (
        <div className="main-wrapper">
            <div className="error-box">
                <h1>{isNetworkError ? "503" : "404" }</h1>
                <h3 className="h2 mb-3">
                    <i className="fas fa-exclamation-circle" /> {isNetworkError ? "Network Error!" : " Ongeldige of verlopen link!"}
                </h3>
                <p className="h4 font-weight-normal mt-2">
                    {isNetworkError ? NETWORK_ERROR : "Uw link is verlopen of ongeldig. Probeer het opnieuw."}
                </p>
                <button onClick={() => navigate('/signIn')} className="btn customized-btn mt-2 animate-none bg-[#A5A5A5] text-white border-none px-7 shadow-none py-3 rounded-[5px]">
                       Back to Login Page
                    </button>
            </div>
        </div>
    );
}

  return (
    <>
      <div className="main-wrapper">
        <div className="sign-in-page">
          <div className="logo md:m-0 mx-8">
            <img src={Logo} alt="" />
          </div>

          <div className="sign-in-form">
            <h2 className="text-[23px] font-bold">Wachtwoord Opnieuw Instellen</h2>
            <p className="my-2">
            voer uw nieuwe wachtwoord in
            </p>
            <form  onSubmit={handleSubmit((data) => submitForm(data))}>

              <div className="mt-7 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Wachtwoord
                </label>
                <input
                  id="wachtwoord"
                  name="wachtwoord"
                  autoComplete="new-password"
                  type={eye ? "text" : "password"}
                  className={`${errors.email ? "is-invalid" : ""} appearance-none w-full py-2 px-3`}
                  {...register("wachtwoord")}
                />
                   <div
                    className="password-toggle-btn"
                    onClick={() => seteye(!eye)}
                  >
                    {eye ? (
                      <FeatherIcon icon="eye-off" />
                    ) : (
                      <FeatherIcon icon="eye" />
                    )}
                  </div>
                  {errors.wachtwoord?.message && <div className="invalid-feedback">{errors.wachtwoord?.message}</div>}
            
              </div>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bevestigWachtwoord">
                Bevestig Wachtwoord
                </label>
                <input
                id="bevestigWachtwoord"
                name="bevestigWachtwoord"
                type={eye ? "text" : "password"}
                autoComplete="new-password"
                  className={`${errors.bevestigWachtwoord ? "is-invalid" : ""} appearance-none w-full py-2 px-3`}
                  {...register("bevestigWachtwoord")}
                />
                <div className="invalid-feedback h-3">{errors.bevestigWachtwoord?.message || " "}</div>
              </div>

            
             <div className="mt-3 p-2">
             <button className="btn btn-active btn-neutral w-full">
                    {
                      isLoading ?
                      <span className='flex items-center gap-1'>
                      <FeatherIcon icon="loader" className={`w-4 h-4 animate-spin`} />
                      Indienen...
                      </span>
                      : "Indienen"
                    }
             </button>
             </div>
            </form>
            <div className="sign-up-link">

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
