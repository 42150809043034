// Loader.js
import React from 'react';

const PageLoader = ({text}) => (
  <div className="flex items-center justify-center h-96">
  <div className="flex items-center space-x-2">
      <div
          className="w-10 h-10 rounded-full animate-spin border-4 border-solid border-t-transparent"
          style={{ borderColor: '#92EEC3 transparent #92EEC3 transparent' }}
      ></div>
      <div className="text-black text-lg">{ text || "Laden even geduld aub..."}</div>
  </div>
</div>
);

export default PageLoader;
