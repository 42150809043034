
export const RoutesAndTheirNames = [
    {
        path:'/dashboard',
        name:'Dashboard',
    },
    {
        path:'/klanten',
        name:'Klanten',
    },
    {
        path:'/eia-overzicht',
        name:'EIA Aanvragen',
    },
    {
        path:'/eia-zon-overzicht',
        name:'EIA Zon Aanvragen',
    },
    {
        path:'/isde-overzicht',
        name:'ISDE Aanvragen',
    },
    {
        path:'/financieel',
        name:'Financieel',
    },
    {
        path:'/aanvraag-details',
        name:'Aanvraag Details',
    },
    {
        path:'/eia-aanvragen',
        name:'EIA Aanvragen',
    },
    {
        path:'/eia-zon-aanvragen',
        name:'EIA Zon Aanvragen',
    },
    {
        path:'/isde-aanvragen',
        name:'ISDE Aanvragen',
    },
    {
        path:'/betaling-details',
        name:'Betaling Details',
    },
    {
        path:'/facturen',
        name:'Facturen',
    },
    
]


export const RoutesFullPath = {
    customerDashboard: '/panel/customer/dashboard',
    aanvraagDetails: '/panel/customer/dashboard/aanvraag-details',
    aanvraagDetailsEIAZonAdmin: '/panel/admin/eia-zon-overzicht/aanvraag-details',
    aanvraagDetailsEIAAdmin: '/panel/admin/eia-overzicht/aanvraag-details',
    aanvraagDetailsISDEAdmin: '/panel/admin/isde-overzicht/aanvraag-details',
    EIAAanraag: '/panel/customer/eia-aanvragen',
    EiaZonAanvragen: '/panel/customer/eia-zon-aanvragen',
    ISDEAanvragen: '/panel/customer/isde-aanvragen',
    BetalingDetails: '/panel/customer/facturen/betaling-details',
}









export const NETWORK_ERROR = 'Please check your internet connection, and try again later.'




export const APPLICATION_STATUS = {
    INBEHANDELING: 'In-behandeling',
    GOEDGEKEURD: 'Goedgekeurd',
    AFGEKEURD: 'Afgekeurd',
    INGEDIEND: 'Ingediend-bij-rvo',
}


export const ErrorsAndMessages = {
    InvalidCredentials: 'Ongeldige inloggegevens',
    emailRequired: 'Email is verplicht',
    emailInvalid: 'Email is ongeldig',
    passwordRequired: 'Wachtwoord is verplicht',
    loginSuccess: 'Login succesvol',
    emailSent: "a reset link has been sent to your email",
    passwordResetSuccess: "Password reset successfully",
}

export const pushToDashboard = (role,navigate) => {
    if(role === 'admin'){
      navigate("/panel/admin/dashboard");
    }else if (role === 'customer'){
      navigate("/panel/customer/dashboard");
    }
  }