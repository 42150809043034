import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Table from "../../components/datatable/datatable";
import SearchComponent from "../../components/search";
import { fetchDashboardData } from "../../core/axios/endpoints";
import { RoutesFullPath } from "../../utils/constants";

const AdminDashboardPage = (props) => {
  const [data, setData] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    totalApplications: 0,
    applicationCounts: {
      eia: 0,
      eiaZon: 0,
      isde: 0,
    },
    totalPaidInvoices: 0,
    latestApplications: [],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const formatNumber = (number) => {
    return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };


  const columns = [
    {
      title: "Naam",
      dataIndex: "naam",
      render: (text, record) => <span>{record?.klantId?.naam}</span>
    },
    {
      title: "Regeling",
      dataIndex: "regling",
      sorter: (a, b) => a.regling.localeCompare(b.regling),
    },
    {
      title: "Datum",
      dataIndex: "createdAt",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Bedrag",
      dataIndex: "totaal",
      render: (text) => <span>€{formatNumber(text || '0')}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchDashboardData();
      setDashboardData(response);
      setData(response.latestApplications.map((item, index) => ({ ...item, key: index })));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (record) => {
    navigate(`${RoutesFullPath.aanvraagDetailsEIAAdmin}/${record?._id}`, {
        state: {admin:true}
    });
  };



  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content admin-pages">
        <div className="py-4 mb-2">
          <div className="grid md:grid-cols-5 grid-cols-1 mt-5 md:gap-[30px]">
            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
              <p className="title text-[15px]">Totaal Aanvragen</p>
              <div className="text-with-icon flex items-center gap-2 mt-3">
                <div className="icon bg-[#E4F0FF]">
                  <FeatherIcon icon="user" size="20" />
                </div>
                <p className="count text-[16px]">{dashboardData.totalApplications}</p>
              </div>
            </div>

            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
              <p className="title text-[15px]">EIA Aanvragen</p>
              <div className="text-with-icon flex items-center gap-2 mt-3">
                <div className="icon bg-[#E4F0FF]">
                  <FeatherIcon icon="user" size="20" />
                </div>
                <p className="count text-[16px]">{dashboardData.applicationCounts.eia}</p>
              </div>
            </div>

            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
              <p className="title text-[15px]">EIA Zon Aanvragen</p>
              <div className="text-with-icon flex items-center gap-2 mt-3">
                <div className="icon bg-[#E4F0FF]">
                  <FeatherIcon icon="user" size="20" />
                </div>
                <p className="count text-[16px]">{dashboardData.applicationCounts.eiaZon}</p>
              </div>
            </div>

            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
              <p className="title text-[15px]">ISDE Aanvragen</p>
              <div className="text-with-icon flex items-center gap-2 mt-3">
                <div className="icon bg-[#E4F0FF]">
                  <FeatherIcon icon="user" size="20" />
                </div>
                <p className="count text-[16px]">{dashboardData.applicationCounts.isde}</p>
              </div>
            </div>

            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
              <p className="title text-[15px]">Betaalde Facturen</p>
              <div className="text-with-icon flex items-center gap-2 mt-3">
                <div className="icon financieel">
                  <span>€</span>
                </div>
                <p className="count text-[16px]">{formatNumber(dashboardData.totalPaidInvoices || '0')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive card p-4 bg-[#FAFBFE]">
          <div className="bg-[#FAFBFE] p-4 text-[20px]">
            <strong className="font-semibold">Recente Aanvragen</strong>
          </div>
          <div className="bg-white rounded-[20px]">
            <Table
              columns={columns}
              response={{ data, totalDataCount: data.length }}
              fetchData={fetchData}
              onRowClick={handleViewClick}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
