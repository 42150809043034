import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "./button";
import { createCustomer } from "../core/axios/endpoints"; // Importing the createCustomer function
import { toastrError, toastrSuccess } from "../utils/alertify";

const CreateUserModal = ({ isOpen, onClose }) => {
  const [validationSchema, setValidationSchema] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const modal = document.getElementById("create-user-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }

  const schema = () => {
    return Yup.object().shape({
      naam: Yup.string().required("Naam is verplicht"),
      telefoonNummer: Yup.string().required("Telefoon Nummer is verplicht"),
      email: Yup.string().required("Email is verplicht").email("Email is invalid"),
    });
  };

  useEffect(() => {
    setValidationSchema(schema());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const submitForm = async (data) => {
    setIsLoading(true);
    try {
      const response = await createCustomer({
        naam: data.naam,
        email: data.email,
        telefoonnummer: data.telefoonNummer,
      });
      toastrSuccess( response?.message  || "User created successfully");
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.error("Error creating user:", error);
      toastrError(error.response?.data?.error || "Error creating user, please try again later");
      setIsLoading(false);
    }
  };

  return (
    <dialog id="create-user-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box w-11/12 max-w-5xl">
        <div className="modal-content md:px-16">
          <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>
          <h1 className="modal-title text-center">
            <strong>Klanten Creëren</strong>
          </h1>

          <form className="update-profile-form mt-10" onSubmit={handleSubmit(submitForm)}>
            <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
              <div>
                <label htmlFor="naam"> Naam </label>
                <input
                  id="naam"
                  name="naam"
                  type="text"
                  autoComplete="new-password"
                  className={`${errors.naam ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("naam")}
                />
                <div className="invalid-feedback h-3">
                  {errors.naam?.message}
                </div>
              </div>

              <div>
                <label htmlFor="email"> Email</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="new-password"
                  className={`${errors.email ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("email")}
                />
                <div className="invalid-feedback h-3">
                  {errors.email?.message}
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-1 gap-10 mt-3 grid-cols-1">
              <div>
                <label htmlFor="telefoonNummer">Telefoon Nummer </label>
                <input
                  id="telefoonNummer"
                  name="telefoonNummer"
                  type="text"
                  autoComplete="new-password"
                  className={`${errors.telefoonNummer ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("telefoonNummer")}
                />
                <div className="invalid-feedback h-3">
                  {errors.telefoonNummer?.message}
                </div>
              </div>
            </div>

            <div className="mt-7 p-2 flex md:justify-end justify-center">
              <Button btnClassNames={`btn-neutral w-min px-16`} text="Creëer" isLoading={isLoading} loadingText="Creëren..." />
            </div>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default CreateUserModal;
