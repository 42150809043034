import React, { useEffect, useState } from "react";
import { Logo, UserMockImage } from "../core/path/images";
import Button from "./button";

const ViewCustomerProfileAdmin = ({ isOpen, onClose, userDetails }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(userDetails);
  }, [userDetails]);

  useEffect(() => {
    const modal = document.getElementById("view-customer-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }



  useEffect(() => {

  }, []);



  return (
    <dialog id="view-customer-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box">

        <div className="modal-content ">

        <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>
        <h1 className="modal-title text-center">
          <strong>Klant Informatie</strong>
        </h1>

        <div className="flex justify-center flex-col items-center gap-5">
          <div className="profile-pic">
            <img className="object-cover" src={user?.profielFoto || UserMockImage} alt="" />
          </div>
        </div>

        <form
          className="update-profile-form mt-5"
          
        >
          <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
            <div>
              <label > Naam </label>
             <p>
                {user?.naam || 'N/A'}
             </p>
            </div>

            <div>
              <label >Bedrijfsnaam </label>
                <p>
                {user?.bedrijfsnaam || 'N/A'}
             </p>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
            <div>
              <label >Telefoonnummer </label>
              <p>
                {user?.telefoonnummer || 'N/A'}
             </p>         
            </div>

            <div>
              <label >Email </label>
               <p>
                {user?.email}
             </p>   
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
            <div>
              <label >Postcode </label>
              <p>
                {user?.postcode || 'N/A'}
             </p>   
              
            </div>

            <div>
              <label >Plaats </label>
              <p>
                {user?.plaats || 'N/A'}
             </p> 
             
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
            <div>
              <label> Bedrag </label>
              <p>
              € {user?.applicationFee || 'N/A'}
             </p>   
              
            </div>

            <div>
              <label >Aangemaakt op </label>
              <p>
                {
                   new Date(user?.createdAt).toLocaleDateString() || 'N/A'
                }
             </p> 
             
            </div>
          </div>
          
            <div className="mt-5"> 
            <div>
              <label >Adres </label>
               <p>
                {user?.adres || 'N/A'}
             </p>   
            </div>
            </div>
        
        </form>
        </div>
      </div>
    </dialog>
  );
};

export default ViewCustomerProfileAdmin;
