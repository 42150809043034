
import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "../../assets/css/datatable/antd.css";
import { onShowSizeChange } from "./pagination";

const Datatable = ({ columns, response, fetchData , SelectedRows,onRowClick }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData({page:pagination.current, pageSize:pagination.pageSize});
  }, [pagination.current, pagination.pageSize]);

  useEffect(() => {
    if(response?.resetPageSize){
      
      setPagination({...pagination ,pageSize:10,total:response?.totalDataCount});
    }else{
      setPagination({...pagination ,total:response?.totalDataCount});
    }
    // 
  }, [response?.data]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    SelectedRows(newSelectedRowKeys)
  };

  const handleTableChange = (pagination) => {
    // 
    setPagination(pagination);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  return (
    <Table
      className="table datanew dataTable no-footer"
      rowSelection={SelectedRows? rowSelection : null}
      columns={columns}
      dataSource={response?.data}
      onRow={(record, rowIndex) => ({
        onClick: () => {onRowClick?onRowClick(record):null},
      })}
      pagination={{
        ...pagination,
        showTotal: (total, range) =>
          ` ${range[0]} to ${range[1]} of ${total} items`,
        showSizeChanger: true,
        pageSizeOptions:[10,50,100,500,1000,1000000000],
        onShowSizeChange: onShowSizeChange,
      }}
      rowKey={(record) => record._id}
      onChange={handleTableChange}
    />
  );
};

export default Datatable;
