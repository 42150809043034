import React, { useEffect } from "react";
import Button from "./button";

const KvkDetailsModal = ({ isOpen, onClose, kvkDetails }) => {
  useEffect(() => {
    const modal = document.getElementById("kvk-details-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }

  return (
    <dialog id="kvk-details-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box w-11/12 max-w-5xl">
        <div className="modal-content">
          <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>
          <h1 className="modal-title text-center text-[20px]">
            <strong>KVK Details</strong>
          </h1>
          {(
            kvkDetails && (
              <div className="mt-10 max-h-96 overflow-y-auto">
                <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
                  <div>
                    <label className="font-semibold">KVK Number: </label>
                    <span>{kvkDetails?.kvkNummer}</span>
                  </div>
                  <div>
                    <label className="font-semibold">Non-Mailing Indicator: </label>
                    <span>{kvkDetails?.indNonMailing}</span>
                  </div>
                  <div>
                    <label className="font-semibold">Company Name: </label>
                    <span>{kvkDetails?.naam}</span>
                  </div>
                  <div>
                    <label className="font-semibold">Formal Registration Date: </label>
                    <span>{kvkDetails?.formeleRegistratiedatum}</span>
                  </div>
                  <div>
                    <label className="font-semibold">Start Date: </label>
                    <span>{kvkDetails?.materieleRegistratie?.datumAanvang}</span>
                  </div>
                  <div>
                    <label className="font-semibold">Total Working Persons: </label>
                    <span>{kvkDetails?.totaalWerkzamePersonen}</span>
                  </div>
                  <div>
                    <label className="font-semibold">Statutory Name: </label>
                    <span>{kvkDetails?.statutaireNaam}</span>
                  </div>
                  {kvkDetails?.handelsnamen?.map((handelsnaam, index) => (
                    <div key={index}>
                      <label className="font-semibold">Trade Name {index + 1}: </label>
                      <span>{handelsnaam?.naam}</span>
                    </div>
                  ))}
                  {kvkDetails?.sbiActiviteiten?.map((activiteit, index) => (
                    <div key={index}>
                      <label className="font-semibold">SBI Code {index + 1}: </label>
                      <span>{activiteit?.sbiCode} - {activiteit?.sbiOmschrijving} - {activiteit?.indHoofdactiviteit}</span>
                    </div>
                  ))}
                  <div>
                    <label className="font-semibold">Self Link: </label>
                    <a href={kvkDetails?._links?.self?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._links?.self?.href}</a>
                  </div>
                  <div>
                    <label className="font-semibold">Establishments Link: </label>
                    <a href={kvkDetails?._links?.vestigingen?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._links?.vestigingen?.href}</a>
                  </div>
                  <div className="md:col-span-2">
                    <h2 className="text-lg font-bold mt-5">Head Office Details</h2>
                    <div>
                      <label className="font-semibold">Branch Number: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.vestigingsnummer}</span>
                    </div>
                    <div>
                      <label className="font-semibold">KVK Number: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.kvkNummer}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Formal Registration Date: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.formeleRegistratiedatum}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Start Date: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.materieleRegistratie?.datumAanvang}</span>
                    </div>
                    <div>
                      <label className="font-semibold">First Trade Name: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.eersteHandelsnaam}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Main Office: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.indHoofdvestiging}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Commercial Establishment: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.indCommercieleVestiging}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Total Working Persons: </label>
                      <span>{kvkDetails?._embedded?.hoofdvestiging?.totaalWerkzamePersonen}</span>
                    </div>
                    {kvkDetails?._embedded?.hoofdvestiging?.adressen?.map((address, index) => (
                      <div key={index}>
                        <label className="font-semibold">Address {index + 1}: </label>
                        <span>{address?.volledigAdres}</span>
                        <div className="ml-4">
                          <label className="font-semibold">Street Name: </label>
                          <span>{address?.straatnaam}</span>
                        </div>
                        <div className="ml-4">
                          <label className="font-semibold">House Number: </label>
                          <span>{address?.huisnummer}{address?.huisletter}</span>
                        </div>
                        <div className="ml-4">
                          <label className="font-semibold">Postal Code: </label>
                          <span>{address?.postcode}</span>
                        </div>
                        <div className="ml-4">
                          <label className="font-semibold">City: </label>
                          <span>{address?.plaats}</span>
                        </div>
                        <div className="ml-4">
                          <label className="font-semibold">Country: </label>
                          <span>{address?.land}</span>
                        </div>
                      </div>
                    ))}
                    <div>
                      <label className="font-semibold">Head Office Self Link: </label>
                      <a href={kvkDetails?._embedded?.hoofdvestiging?._links?.self?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._embedded?.hoofdvestiging?._links?.self?.href}</a>
                    </div>
                    <div>
                      <label className="font-semibold">Head Office Establishments Link: </label>
                      <a href={kvkDetails?._embedded?.hoofdvestiging?._links?.vestigingen?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._embedded?.hoofdvestiging?._links?.vestigingen?.href}</a>
                    </div>
                    <div>
                      <label className="font-semibold">Head Office Basic Profile Link: </label>
                      <a href={kvkDetails?._embedded?.hoofdvestiging?._links?.basisprofiel?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._embedded?.hoofdvestiging?._links?.basisprofiel?.href}</a>
                    </div>
                    <div>
                      <label className="font-semibold">Head Office Establishment Profile Link: </label>
                      <a href={kvkDetails?._embedded?.hoofdvestiging?._links?.vestigingsprofiel?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._embedded?.hoofdvestiging?._links?.vestigingsprofiel?.href}</a>
                    </div>
                  </div>
                  <div className="md:col-span-2">
                    <h2 className="text-lg font-bold mt-5">Owner Details</h2>
                    <div>
                      <label className="font-semibold">RSIN: </label>
                      <span>{kvkDetails?._embedded?.eigenaar?.rsin}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Legal Form: </label>
                      <span>{kvkDetails?._embedded?.eigenaar?.rechtsvorm}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Extended Legal Form: </label>
                      <span>{kvkDetails?._embedded?.eigenaar?.uitgebreideRechtsvorm}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Owner Self Link: </label>
                      <a href={kvkDetails?._embedded?.eigenaar?._links?.self?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._embedded?.eigenaar?._links?.self?.href}</a>
                    </div>
                    <div>
                      <label className="font-semibold">Owner Basic Profile Link: </label>
                      <a href={kvkDetails?._embedded?.eigenaar?._links?.basisprofiel?.href} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{kvkDetails?._embedded?.eigenaar?._links?.basisprofiel?.href}</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
          <div className="mt-7 p-2 flex md:justify-end justify-center">
            <Button btnClassNames="btn-neutral w-min px-16" text="Close" onClick={handleClose} />
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default KvkDetailsModal;
