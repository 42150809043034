/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Logo, Logout, UserMockImage } from "../../core/path/images";
import { Link, useNavigate, } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useUser } from "../../core/context/user";
import Modal from "../../components/profile-modal";
import { RoutesAndTheirNames } from "../../utils/constants";
import { LogoutAndRemoveState } from "../../core/axios/auth";

const Header = (props) => {
    const { userData,isCurrentUserAdmin, updateUser } = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const sidebarOverlay = () => {
        document.querySelector(".main-wrapper").classList.toggle("slide-nav");
        document.querySelector(".sidebar-overlay").classList.toggle("opened");
        document.querySelector("html").classList.toggle("menu-opened");
    };


    const getCurrentPage = () => {
        return RoutesAndTheirNames.find((route) => window.location.pathname.includes(route.path))?.name || "";
    }

    const logout = async  () => {
        
      await LogoutAndRemoveState();
        navigate("/signIn");
        updateUser(null);
    }

    return (
        <>
            <div className="header">
                {/* Logo */}
                <div
                    className={`header-left`}
                >
                    <Link to="/main/dashboard" className="logo logo-normal">
                        <img src={Logo} alt="" />
                    </Link>

                    <Link to="/main/dashboard" className="logo-small">
                        <img src={Logo} alt="" />
                    </Link>

                </div>
                <Link
                    id="mobile_btn"
                    className="mobile_btn"
                    to="#"
                    onClick={sidebarOverlay}
                >
                    <span className="bar-icon">
                        <span />
                        <span />
                        <span />
                    </span>
                </Link>
                {/* Header Menu */}

                <div className="flex items-center justify-between py-2">

                    <div className="current-page ms-5">
                        <h1 >{getCurrentPage()}</h1>
                      
                    </div>


                    <ul className="nav user-menu w-fit">
                        {/* <li className="nav-item nav-item-box">
                        <Link
                            to="#"
                            id="btnFullscreen"
                            onClick={() => { }}
                        >
                            <FeatherIcon icon="mail" />
                        </Link>
                    </li>

                    <li className="nav-item nav-item-box">
                        <Link >
                            <FeatherIcon icon="bell" />
                        </Link>
                    </li> */}

                        <li className="nav-item dropdown has-arrow main-drop">

                            <details className="dropdown">
                                <summary className="btn-profile-dropdown btn p-0 m-0">
                                    <span className="user-info">
                                        <span className="user-letter">
                                            <img src={userData?.profielFoto  || UserMockImage} alt="" />
                                        </span>
                                        <span className="user-detail">
                                            <span className="user-name">{userData?.naam || ""}</span>
                                            <span className="user-role mt-1 text-start">{
                                                userData?.role === "admin" ? "Admin" : userData?.role === "customer" ? "Customer" : ""
                                            }</span>
                                        </span>
                                    </span>
                                </summary>
                                <div className="dropdown-menu menu-drop-user dropdown-content z-[1] p-2 shadow bg-base-100 w-auto">

                                 { !isCurrentUserAdmin() &&   <Link className="dropdown-item" onClick={() => setIsModalOpen(true)} >
                                       <FeatherIcon icon="user" />                                      
                                        My Profile
                                    </Link>}
                                    <Link onClick={logout} className="dropdown-item logout pb-0" >
                                        <img src={Logout} className="me-2" alt="img" />
                                        Logout
                                    </Link>

                                </div>
                            </details>


                        </li>
                    </ul>

                </div>




                <div className="mobile-user-menu dropdown dropdown-auto dropdown-end">
                    <details className="dropdown">
                        <summary className="inline-flex items-center mt-2 me-2  p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50">
                            <FeatherIcon icon="more-vertical" />
                        </summary>
                        <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">

                        { !isCurrentUserAdmin() && 
                        <Link className="dropdown-item" onClick={() => setIsModalOpen(true)}>
                                       <FeatherIcon icon="user" />                                      
                                        My Profile
                                    </Link>
                            }

                            <Link className="dropdown-item logout pb-0" onClick={logout} >
                                <img src={Logout} className="me-2" alt="img" />
                                Logout
                            </Link>
                        </ul>
                    </details>
                </div>


                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
                {/* /Mobile Menu */}
            </div>
        </>
    );
};

export default Header;
