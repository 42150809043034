import { Popover } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React from 'react';

export default function CustomPopover({ children,position }) {
    return (
            <Popover content={children}
            className={`max-w-[400px] absolute ${position}`} trigger="hover">
            <FeatherIcon icon="info" className="info-icon" />
            </Popover>
    );
}