import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/search";
import Table from '../../components/datatable/datatable';
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Dropdown from "../../components/dropdown";
import { adminDeleteApplication, adminFetchAllApplications, deleteApplication } from "../../core/axios/endpoints";
import Papa from "papaparse";
import Swal from "sweetalert2";
import { RoutesFullPath } from "../../utils/constants";

const AdminEIAZonAanvragenPage = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SelectedRows, setSeletedRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [statistics, setStatistics] = useState({
        totalApplications: 0,
        totalAmount: 0,
    });

    const navigate = useNavigate();


    const [activeSegment, setActiveSegment] = useState('new');

    useEffect(() => {
        fetchData({ page: 1, pageSize: 10, resetPageSize: false });
    }, [activeSegment]);


    const handleViewClick = (record) => {
        navigate(`${RoutesFullPath.aanvraagDetailsEIAZonAdmin}/${record?._id}`, {
            state: {admin:true}
        });
      };
    

    const closeDropdown = (record) => {
        document.getElementById(`dropdown-klanten-action-${record?.id}`)?.removeAttribute("open");
    };

    const columns = [
        {
            title: "Naam Klant",
            dataIndex: "klantId",
            render: (klant) => klant ? klant.naam : "",
            sorter: (a, b) => a.klantId?.naam.localeCompare(b.klantId?.naam),
        },
        {
            title: "KVK-Nummer",
            dataIndex: "KvkNummer",
            sorter: (a, b) => a.KvkNummer.localeCompare(b.KvkNummer),
        },
        {
            title: "Kenmerk",
            dataIndex: "Kenmerk",
            sorter: (a, b) => a.Kenmerk.localeCompare(b.Kenmerk),
        },
        {
            title: "Regeling",
            dataIndex: "regling",
            sorter: (a, b) => a.regling.localeCompare(b.regling),
        },
        {
            title: "Datum",
            dataIndex: "datumOpdrachtBevestiging",
            render: (text) => <span>{text}</span>,
            sorter: (a, b) => a.datumOpdrachtBevestiging - b.datumOpdrachtBevestiging,
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: "Action",
            render: (record) => (
                <Dropdown className="dropdown-action dropdown dropdown-end" id={"dropdown-klanten-action-" + record?.id}>
                    <summary className="inline-flex items-center mt-2 me-2 p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50 ">
                        <FeatherIcon icon="more-vertical" />
                    </summary>
                    <ul className="dropdown-content z-[1] flex flex-col gap-1 w-fit">
                        <a onClick={() => { closeDropdown(record); handleViewClick(record) }}
                            className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1">
                            View
                        </a>
                        {/* <a onClick={() => { closeDropdown(record) }} className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1">
                            Bewerk
                        </a> */}
                    </ul>
                </Dropdown>
            ),
        },
    ];

    const fetchData = async ({ page, pageSize, resetPageSize }) => {
        try {
            setLoading(true);
            const response = await adminFetchAllApplications(page, pageSize, searchText, "EIA Zon",activeSegment);
            const result = {
                data: response.docs.map((application, index) => ({ ...application, key: index })),
                totalDataCount: response.totalDocs,
                resetPageSize,
            };
            setData(result);
            setStatistics({
                totalApplications: response.totalCount,
                totalAmount: response.totalAmount,
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData({ page: 1, pageSize: 10, resetPageSize: false });
    }, [searchText]);

    const handleSearch = (query) => {
        setSearchText(query);
    };

    const handleDelete = async () => {
        Swal.fire({
            title: 'Weet je het zeker?',
            text: "Dit kan niet ongedaan worden gemaakt!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ja, verwijder het!',
            cancelButtonText: 'Annuleren'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                   
                    await adminDeleteApplication(SelectedRows);
                    fetchData({ page: 1, pageSize: 10, resetPageSize: false });
                    setSeletedRows([]);
                    Swal.fire(
                        'Verwijderd!',
                        'De geselecteerde aanvragen zijn verwijderd.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting applications:", error);
                    Swal.fire(
                        'Mislukt!',
                        'Er is een fout opgetreden bij het verwijderen van de aanvragen.',
                        'error'
                    );
                }
            }
        });
    };

    const handleExport = () => {
        const csvData = data.data.map(row => ({
            ID: row._id,
            Naam: row.klantId?.naam || '',
            KvkNummer: row.KvkNummer,
            VoorLetters: row.VoorLetters,
            Geslacht: row.geslacht,
            PostCode: row.postCode,
            StraatNaam: row.straatNaam,
            Email: row.email,
            Kenmerk: row.Kenmerk,
            MeldCodes: row?.meldCodes,
            HandelsNaam: row.handelsNaam,
            AchterNaam: row.achterNaam,
            HuisNummer: row.huisNummer,
            Plaats: row.plaats,
            TelefoonNummer: row.teleFoonNummer,
            DatumOpdrachtBevestiging: row.datumOpdrachtBevestiging,
            TotaalVermogen: row.totaalVermogen, // this only available in eia-zon-overzicht
            AantalZonnepanelen: row.aantalZonnepanelen, // this only available in eia-zon-overzicht
            // Type: row.type, // this only available in isde-overzicht
            Regeling: row.regling,
            Status: row.status,
            ExclusiveBTW: row.exclusiveBTW,
            InclusiveBTW: row.inclusiveBTW,
            Totaal: row.totaal
        }));
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "aanvragen.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const RowChanges = (rows) => {
        setSeletedRows(rows)
        //  
        

    }

    const formatNumber = (number) => {
        return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      };
      
    return (
        <div className="page-wrapper">
            <div className="content admin-pages">
                <div className="py-4">
                     <div className="grid md:grid-cols-3 grid-cols-1 mt-5 md:gap-[30px]">
                        <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                            <p className="title text-[16px]">EIA Zon Aanvragen</p>
                            <div className="text-with-icon flex items-center gap-2 mt-3">
                                <div className="icon bg-[#E4F0FF]">
                                    <FeatherIcon icon="user" size="20" />
                                </div>
                                <p className="count text-[16px]">{statistics?.totalApplications}</p>
                            </div>
                        </div>

                        <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                            <p className="title text-[15px]">Totaal Omzet</p>
                            <div className="text-with-icon flex items-center gap-2 mt-3">
                                <div className="icon financieel">
                                    <span>€</span>
                                </div>
                                  <p className="count text-[16px]">{formatNumber(statistics?.totalAmount || '0')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-10">
                    <SearchComponent onSearch={handleSearch}  type={'applications'} />
                </div>
                <div className="table-responsive card p-4 bg-[#FAFBFE]">
                    <div className="bg-[#FAFBFE] px-0 py-3 flex justify-between items-center">
                        <div>
                            <button disabled={!SelectedRows?.length} className="btn animate-none me-2 bg-[#EB4747] bg-opacity-20 text-black border-none hover:bg-opacity-30 hover:bg-[#EB4747] px-4 shadow-none py-1 disabled:bg-opacity-30 disabled:opacity-50 disabled:text-black disabled:bg-[#EB4747]" onClick={handleDelete}>
                                <FeatherIcon
                                    icon="trash-2"
                                    className="h-5 text-[#EB4747]"
                                ></FeatherIcon>
                                Verwijder
                            </button>

                            <button disabled={!SelectedRows?.length} className="btn animate-none bg-[#DEF8EC] text-black border-none hover:bg-[#DEF8EC] px-4 shadow-none py-1  disabled:opacity-50 disabled:text-black disabled:bg-[#DEF8EC]" onClick={handleExport}>
                                <FeatherIcon
                                    icon="download"
                                    className="h-5 text-[#82A695]"
                                ></FeatherIcon>
                                Exporteer
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="inline-flex rounded-lg shadow h-[48px]">
                                <button
                                    className={`px-4  w-[105px] rounded-l-lg ${activeSegment === 'new' ? 'bg-[#DEF8EC] text-[#82A695]' : 'bg-gray-200 text-gray-700'}`}
                                    onClick={() => setActiveSegment('new')}
                                >
                                    Actief
                                </button>
                                <button
                                    className={`px-4 w-[105px] rounded-r-lg ${activeSegment === 'old' ? 'bg-[#DEF8EC] text-[#82A695]' : 'bg-gray-200 text-gray-700'}`}
                                    onClick={() => setActiveSegment('old')}
                                >
                                    OUD
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-[20px]">
                        <Table
                            columns={columns}
                            response={data}
                            fetchData={fetchData}
                            SelectedRows={RowChanges}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminEIAZonAanvragenPage;
