// Loader.js
import React from 'react';

const MainLoader = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-[1000000]">
    <div className="flex items-center space-x-2">
      <div
        className="w-10 h-10 rounded-full animate-spin border-4 border-solid border-t-transparent"
        style={{ borderColor: '#92EEC3 transparent #92EEC3 transparent' }}
      ></div>
      <div className="text-white text-lg">Loading...</div>
    </div>
  </div>
);

export default MainLoader;
