/* eslint-disable no-unused-vars */
import React  from 'react';

export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <button className="link-style">Previous</button>;
  }
  if (type === "next") {
    return <button className="link-style">Next</button>;
  }
  return originalElement;
}

  export function onShowSizeChange(current, pageSize) {
    // 
  }