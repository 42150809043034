import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getInvoiceById } from "../../core/axios/endpoints";
import PageLoader from "../../components/page-loader";
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceDocument from "../../components/invoice-pdf";
import { NETWORK_ERROR } from "../../utils/constants";

const ClientBetalingDetailsPage = (props) => {
    const [BetalingDetails, setBetalingDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const { facturenId } = useParams();
    const [isNetworkError, setIsNetworkError] = useState(false);
    const navigate = useNavigate();
    const getBetalingDetails = async () => {
        setLoading(true);
        try {
            const data = await getInvoiceById(facturenId);
            setBetalingDetails(data);
        } catch (err) {
            
            if(err?.isNetworkError){
                setIsNetworkError(true);
            }
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBetalingDetails();
    }, []);

    const formatNumber = (number) => {
        return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      };
    if (loading) {
        return <PageLoader />;
    }

    if (isError) {
        return (
            <div className="main-wrapper">
                <div className="error-box">
                    <h1>{isNetworkError ? "503" : "404" }</h1>
                    <h3 className="h2 mb-3">
                        <i className="fas fa-exclamation-circle" /> Oops! {isNetworkError ? "Network Error!" : ""}
                    </h3>
                    <p className="h4 font-weight-normal mt-2">
                        {isNetworkError ? NETWORK_ERROR : "Er is iets misgegaan. Probeer het later opnieuw"}
                    </p>
                    <button onClick={() => navigate(-1)} className="btn customized-btn mt-2 animate-none bg-[#A5A5A5] text-white border-none px-7 shadow-none py-3 rounded-[5px]">
                       Go Back
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="table-responsive">
                    <div className="grid grid-cols-3 mt-3 py-4 border-b-[#DDDDDD] text-[16px] border-b-[1px] text-[#999999]">
                        <div>Regeling</div>
                        <div>Datum betaling</div>
                        <div>Bedrag</div>
                    </div>

                    <div className="grid grid-cols-3 md:mb-[100px] mt-3 py-4 border-b-[#DDDDDD] text-[16px] text-black">
                        <div>{BetalingDetails?.application?.regling || "N/A"}</div>
                        <div>{ BetalingDetails?.betaaldDateTime ? new Date(BetalingDetails?.betaaldDateTime).toLocaleDateString() : "N/A"}</div>
                        <div>€{BetalingDetails?.application?.totaal || "0,00"}</div>
                    </div>

                    <div className="grid grid-cols-1">
                        <div className="mt-10 flex justify-end">
                            <div className="details-card w-fit bg-[#F4F4F4] py-6 px-8 rounded-[20px] font-[500] text-[17px]">
                                <p className="mb-5">Factuurinformatie</p>
                                <div>
                                    <p className="flex justify-between md:gap-[250px] gap-[130px]">
                                        <span>Subtotaal:</span> 
                                        <span>€{formatNumber(BetalingDetails?.application?.exclusiveBTW || '0')}</span>
                                    </p>
                                    <p className="flex justify-between md:gap-[250px] gap-[130px]">
                                        <span>BTW:</span> 
                                        <span>€{formatNumber(BetalingDetails?.application?.inclusiveBTW || '0')}</span>
                                    </p>
                                    <hr className="border-black opacity-50 my-1"></hr>
                                    <p className="flex justify-between md:gap-[250px] gap-[130px]">
                                        <span>Totaal</span> 
                                        <span>€{formatNumber(BetalingDetails?.application?.totaal || '0')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 mt-10 bg-white mb-5">
                        <div className="flex justify-start bg-white" onClick={() => navigate(-1)}>
                            <button className="btn animate-none bg-[#A5A5A5] text-white border-none hover:bg-[#A5A5A5] px-7 shadow-none py-3 rounded-[5px]">Terug</button>
                        </div>
                        <div className="flex justify-end bg-white">
                      
                   <PDFDownloadLink
                document={<InvoiceDocument invoiceData={BetalingDetails} />}
                fileName={BetalingDetails?.InvoiceId + ".pdf"}
                className="btn animate-none bg-[#A5A5A5] text-white border-none hover:bg-[#A5A5A5] px-7 shadow-none py-3 rounded-[5px] hover:text-white"
              >
                 {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Download PDF'
               }
                  </PDFDownloadLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientBetalingDetailsPage;
