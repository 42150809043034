import { instance } from "./index";


export const Login = async (payload) => {
    const response = await instance.post(`/user/login`, payload)
    return response.data;
};

export const refreshUserToken = async (payload) => {
    const response = await instance.post(`/user/refresh-token`, payload)
    return response.data;
}

export const forgetPassword = async (payload) => {
    const response = await instance.post(`/user/forget-password`, payload)
    return response.data;
}

export const resetPassword = async (payload) => {
    const response = await instance.post(`/user/reset-password`, payload)
    return response.data;
}
  

export const validateResetToken = async (payload) => {
    const response = await instance.post(`/user/validate-reset-token`, payload)
    return response.data;
}

export const validateCompleteProfileToken = async (payload) => {
    const response = await instance.post(`/user/validate-complete-profile-token`, payload)
    return response.data;

}

export const completeProfile = async (payload) => {
  const response = await instance.post(`/user/complete-profile`, payload)
  return response.data;
};

export const updateProfile = async (id, payload) => {
  const response = await instance.put(`/user/update-profile/${id}`, payload)
  return response.data;
};

export const adminGetAllUsers = async (page = 1, limit = 10, searchText = '') => {
  const response = await instance.get('/users/all', {
    params: { page, limit, searchText }
  });
  return response.data;
};

export const createCustomer = async (payload) => {
  const response = await instance.post(`/user/create-user`, payload)
  return response.data;
};

export const updateCustomerTax = async (id, payload) => {
  const response = await instance.put(`/user/update-user-tax/${id}`, payload)
  return response.data;
};

export const updateUserApiLimits = async (id, payload) => {
  const response = await instance.put(`/user/update-api-limits/${id}`, payload)
  return response.data;
};

export const deleteCustomers = async (ids) => {
  const response = await instance.post(`/user/delete-users`, { ids })
  return response.data;
};

export const changeCustomerStatus = async (id, payload) => {
  const response = await instance.put(`/user/update-status/${id}`, payload)
  return response.data;
};



// application apis

export const createApplication = async (payload) => {
  const response = await instance.post(`/application/create`, payload);
  return response.data;
};

export const updateApplication = async (id, payload) => {
  const response = await instance.put(`/application/update/${id}`, payload);
  return response.data;
};

export const getApplicationById = async (id) => {
  const response = await instance.get(`/application/${id}`);
  return response.data;
};

export const adminDeleteApplication = async (ids) => {
  const response = await instance.post(`/applications/admin/delete`, { ids });
  return response.data;
};

export const fetchAllApplications = async (page = 1, limit = 10, searchText = '') => {
  const response = await instance.get('/applications/all', {
    params: { page, limit, searchText }
  });
  return response.data;
};

export const adminFetchAllApplications = async (page = 1, limit = 10, searchText = '', regling = '', view) => {
  const response = await instance.get('/applications/admin/all', {
    params: { page, limit, searchText, regling,view }
  });
  return response.data;
};

export const adminUpdateApplicationStatus = async (id, payload) => {
  const response = await instance.put(`/application/admin/update/${id}`, payload);
  return response.data;
};



// invoice apis

export const fetchAllInvoices = async (page = 1, limit = 10, searchText = '') => {
  const response = await instance.get('/invoices/all', {
    params: { page, limit, searchText }
  });
  return response.data;
};
export const adminDeleteInvoice = async (ids) => {
  const response = await instance.post(`/invoices/admin/delete`, { ids });
  return response.data;
};

export const adminUpdateInvoiceStatus = async (id, payload) => {
  const response = await instance.put(`/invoice/admin/update/${id}`, payload);
  return response.data;
};

export const getInvoiceById = async (id) => {
  const response = await instance.get(`/invoice/${id}`);
  return response.data;
};

export const getAllUserInvoices = async (page = 1, limit = 10, searchText = '') => {
  const response = await instance.get('/invoices/user/all', {
    params: { page, limit, searchText }
  });
  return response.data;

}

// dashboard apis
export const fetchDashboardData = async () => {
  const response = await instance.get(`/admin/dashboard-data/`);
  return response.data;
}

// cloudinary apis

export const uploadImage = async (file, token) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await instance.post(`/cloudinary/upload${token ? `?token=${token}`: '' }`, formData);
    return response.data;
}

//others 


export const fetchSearchSuggestions = async (type,searchText) => {
  const response = await instance.get(`/search-suggestions`, {
    params: { type, searchText }
  });
  return response.data;
};

export const fetchUserCompanyDetails = async (kvkNumber) => {
  const response = await instance.get(`/user/company-details/${kvkNumber}`);
  return response.data;
}