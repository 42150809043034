
import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/search";
import Table from '../../components/datatable/datatable'
import { useNavigate } from "react-router-dom";
import { fetchAllApplications } from "../../core/axios/endpoints";
import { RoutesFullPath } from "../../utils/constants";
const ClientDashboardPage = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Kvk Nummer",
      dataIndex: "KvkNummer",
      sorter: (a, b) => a.KvkNummer - b.KvkNummer,
    },
    {
      title: "Kenmerk",
      dataIndex: "Kenmerk",
      sorter: (a, b) => a.Kenmerk - b.Kenmerk,
    },
    {
      title: "Regeling",
      dataIndex: "regling",
      sorter: (a, b) => a.regling - b.regling,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "Aangemaakt",
      dataIndex: "createdAt",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
  ];



  const fetchData = async ({ page, pageSize, resetPageSize }, searchText='') => {
    setLoading(true);
    await fetchAllApplications(page,pageSize,searchText).then(async data => {
      const result = await new Promise((resolve) => {
        resolve({
          data: data?.docs?.map((item, index) => ({ ...item, id: index })) || [],
          totalDataCount: data?.totalDocs || 0,
          resetPageSize
        })
      });
      setData(result);
    }).catch(err => {
      
    }).
      finally(() => setLoading(false));
  };



  const handleRowClick = (record) => {
    navigate(`${RoutesFullPath.aanvraagDetails}/${record?._id}`);
  };


  useEffect(() => {

  });

  const handleSearch = (query) => {
    fetchData({ page: 1, pageSize: 10, resetPageSize: false },query);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content  ">
          <div className="p-4">
            <SearchComponent onSearch={handleSearch} type={'applications'} />
          </div>
          <div className="table-responsive">
            <Table
              columns={columns}
              response={data}
              fetchData={fetchData}
              onRowClick={handleRowClick}
            />
          </div>

        </div>
      </div>


    </>
  );
};

export default ClientDashboardPage;
