import React, { useEffect, useState } from "react";
import { Logo, UserMockImage } from "../core/path/images";
import Button from "./button";
import { APPLICATION_STATUS } from "../utils/constants";

const AdminRejectAanvraagModal = ({ isOpen, onClose, onSubmit }) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    const modal = document.getElementById("reject-reason-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }



  useEffect(() => {

  }, []);

  const submitForm = () => {
    if (reason === "") {
        setError("Please enter a reason");
      return;
    }
    setError("");
    onSubmit(reason);
    handleClose();
  };



  return (
    <dialog id="reject-reason-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box">

        <div className="modal-content ">

        <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>
        <h1 className="modal-title text-center">
          <strong>Reden voor Afgekeurd</strong>
        </h1>
        <div
          className="update-profile-form mt-5"
          
        >
          <div className="grid md:grid-cols-1 gap-10 mt-3 grid-cols-1">
            <div>
            <div className="grid md:grid-cols-1 gap-10 mt-3 grid-cols-1">
            <div>
              <textarea
                id="reason"
                name="reason"
                type="text"
                placeholder="Voer de reden voor de afwijzing in"
                rows={8}
                className={`appearance-none w-full py-2 px-3 dark:bg-white`}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <div className="invalid-feedback h-3">
                {error}
              </div>
            </div>
          </div>
            </div>
          </div>
        
          <div className="py-2 flex md:justify-end justify-center">
            <Button btnClassNames={`btn-neutral w-min px-16`} text={APPLICATION_STATUS.AFGEKEURD} onClick={()=>submitForm()} />
          </div>
        </div>
        
        </div>
      </div>
    </dialog>
  );
};

export default AdminRejectAanvraagModal;
