import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer';
import { Logo, PoppinsBold, PoppinsSemiBold, PoppinsMedium, PoppinsRegular, PoppinsItalic } from '../core/path/images';

// Register the local fonts
Font.register({
  family: 'Poppins',
  fonts: [
    { src: PoppinsRegular, fontWeight: 400 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsSemiBold, fontWeight: 600 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsItalic, fontStyle: 'italic' },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Poppins',
    fontSize: 10,
    color: '#333',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    height: 50,
  },
  companyInfo: {
    textAlign: 'right',
    fontSize: 10,
  },
  section: {
    marginBottom: 10,
    fontSize: 10,
  },
  sectionHeader: {
    marginBottom: 10,
    fontSize: 10,
    fontWeight: 700,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: '#A5A5A5',
  },
  tableRowData: {
    flexDirection: 'row',
  },
  tableCol: {
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    marginTop: 5,
    marginBottom: 5,
    padding: 5,
    fontSize: 12,
    fontWeight: 700,
  },
  tableCellData: {
    marginTop: 5,
    marginBottom: 5,
    padding: 5,
    fontSize: 10,
  },
  total: {
    marginTop: 165,
    fontSize: 10,
    textAlign: 'right',
  },
  footer: {
    marginTop: 20,
    paddingTop: 10,
    borderTop: '1px solid #eee',
    fontSize: 8,
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 20,
    gap: 20,
  },
  button: {
    width: '33%',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 20,
    backgroundColor: '#D9D9D9',
    display: 'flex',
    fontSize: 10,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000000',
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 5,
  },
  textColLabel: {
    width: 70,
    fontWeight: 700,
    textAlign: 'right',
  },
  textColSeparator: {
    width: 10,
    textAlign: 'center',
  },
  textColCurrency: {
    width: 5,
    textAlign: 'center',
  },
  textColValue: {
    width: 70,
    textAlign: 'right',
  },
  boldText: {
    fontWeight: 700,
  },
  italicText: {
    fontStyle: 'italic',
  },
});

const InvoiceDocument = ({ invoiceData }) => {
  
  const formatNumber = (number) => {
    return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  
  return(
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={Logo} style={styles.logo} />
        <View style={styles.companyInfo}>
          <Text>Adres: Leonard Springerlaan 502</Text>
          <Text>KvK nr: 81037236</Text>
          <Text>BTW nr: NL003520700B84</Text>
          <Text>Bank: Rabobank</Text>
          <Text>IBAN: NL05RABO 0382 4452 60</Text>
          <Text>BIC: RABONL2U</Text>
          <Text>Tel: +31 06 30 17 48 64</Text>
          <Text>Email: info@subsidie2gether.nl</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text>{invoiceData?.user?.bedrijfsnaam}</Text>
        <Text>{invoiceData?.user?.naam}</Text>
        <Text>{invoiceData?.user?.adres}</Text>
        <Text>{invoiceData?.user?.postcode}, {invoiceData?.user?.plaats}</Text>
      </View>

      <Text style={{ textAlign: 'left', marginBottom: 5, fontSize: 16, fontWeight: 600 }}>Factuur</Text>

      <View style={styles.section}>
        <Text>Factuurdatum: {
          new Date(invoiceData?.createdAt).toLocaleDateString('nl-NL', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })
        }</Text>
        <Text>Uw Kenmerk: {invoiceData?.application?.Kenmerk}</Text>
        <Text>Factuurnummer: {invoiceData?.InvoiceId}</Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: '50%' }]}>
            <Text style={styles.tableCell}>Omschrijving</Text>
          </View>
          <View style={[styles.tableCol, { width: '15%' }]}>
            <Text style={styles.tableCell}>Aantal</Text>
          </View>
          <View style={[styles.tableCol, { width: '15%' }]}>
            <Text style={styles.tableCell}>Stukprijs</Text>
          </View>
          <View style={[styles.tableCol, { width: '20%' }]}>
            <Text style={styles.tableCell}>Bedrag</Text>
          </View>
        </View>
        <View style={styles.tableRowData}>
          <View style={[styles.tableCol, { width: '50%' }]}>
            <Text style={styles.tableCellData}>Subsidie aanvraag {invoiceData?.application?.regling}</Text>
          </View>
          <View style={[styles.tableCol, { width: '15%' }]}>
            <Text style={styles.tableCellData}>1x</Text>
          </View>
          <View style={[styles.tableCol, { width: '15%' }]}>
            <Text style={styles.tableCellData}>€{formatNumber(invoiceData?.application?.exclusiveBTW || '0')}</Text>
          </View>
          <View style={[styles.tableCol, { width: '20%' }]}>
            <Text style={styles.tableCellData}>€{formatNumber(invoiceData?.application?.exclusiveBTW || '0')}</Text>
          </View>
        </View>
      </View>

      <View style={styles.total}>
        <View style={styles.textRow}>
          <Text style={styles.textColLabel}>Subtotaal</Text>
          <Text style={styles.textColSeparator}>:</Text>
          <Text style={styles.textColCurrency}></Text>
          <Text style={styles.textColValue}>€ {formatNumber(invoiceData?.application?.exclusiveBTW || '0')}</Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.textColLabel}>BTW 21%</Text>
          <Text style={styles.textColSeparator}>:</Text>
          <Text style={styles.textColCurrency}></Text>
          <Text style={styles.textColValue}>€ {formatNumber(invoiceData?.application?.inclusiveBTW || '0')}</Text>
        </View>
        <View style={styles.textRow}>
          <Text style={styles.textColLabel}>Totaal</Text>
          <Text style={styles.textColSeparator}>:</Text>
          <Text style={styles.textColCurrency}></Text>
          <Text style={styles.textColLabel}>€ {formatNumber(invoiceData?.application?.totaal || '0')}</Text>
        </View>
      </View>

      <View style={styles.buttons}>
        <View style={styles.button}>
          <Text style={styles.boldText}>Rabobank IBAN Rekening</Text>
          <Text style={styles.italicText}> NL05RABO 0382 4452 60</Text>
        </View>
        <View style={styles.button}>
          <Text style={styles.boldText}>Factuurnummer</Text>
          <Text style={styles.italicText}>{invoiceData?.InvoiceId}</Text>
        </View>
        <View style={styles.button}>
          <Text style={styles.boldText}>Factuur bedrag</Text>
          <Text style={styles.italicText}>€{formatNumber(invoiceData?.application?.totaal || '0')}</Text>
        </View>
      </View>

      <View style={styles.footer}>
      <Text>Betaling graag binnen 14 dagen na factuurdatum onder vermelding van factuurnummer.</Text>
      </View>
    </Page>
  </Document>
)};

export default InvoiceDocument;
