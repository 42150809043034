import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.scss';
import App from './pages/App';
import 'react-loading-skeleton/dist/skeleton.css'
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
const config = '/'
root.render(
  <Router basename={`${config}`}>
  <Routes>
    <Route  path='/*' element={<App/>} />
  </Routes>
</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
