import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/search";
import Table from '../../components/datatable/datatable';
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Dropdown from "../../components/dropdown";
import { fetchAllInvoices, adminDeleteInvoice, adminUpdateInvoiceStatus } from "../../core/axios/endpoints";
import Papa from "papaparse";
import Swal from "sweetalert2";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocument from "../../components/invoice-pdf";

const AdminFinancieelPage = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SelectedRows, setSeletedRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [statistics, setStatistics] = useState({
        countBetaald: 0,
        countNietBetaald: 0,
        totalAmountBetaald: 0,
    });

    const navigate = useNavigate();

    const closeDropdown = (record) => {
        document.getElementById(`dropdown-klanten-action-${record?.id}`)?.removeAttribute("open");
    };

    const columns = [
        {
            title: "Factuur-Nummer",
            dataIndex: "InvoiceId",
            sorter: (a, b) => a.InvoiceId.localeCompare(b.InvoiceId),
        },
        {
            title: "Naam",
            dataIndex: ["user", "naam"],
            sorter: (a, b) => a.user.naam.localeCompare(b.user.naam),
        },
        {
            title: "Email",
            dataIndex: ["user", "email"],
            sorter: (a, b) => a.user.email.localeCompare(b.user.email),
        },
        {
            title: "Bedrijf",
            dataIndex: ["user", "bedrijfsnaam"],
            sorter: (a, b) => a.user.bedrijfsnaam.localeCompare(b.user.bedrijfsnaam),
        },
      
        {
            title: "Regeling",
            dataIndex: ["application", "regling"],
            sorter: (a, b) => a.application.regling.localeCompare(b.application.regling),
        },
        {
            title: "Bedrag",
            dataIndex: ["application", "totaal"],
            render: (a) => `€ ${formatNumber(a || '0')}`,
            sorter: (a, b) => a.application.totaal - b.application.totaal,
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: "Action",
            render: (record) => (
                <>
                    <Dropdown className="dropdown-action dropdown dropdown-end" id={"dropdown-klanten-action-" + record?.id}>
                        <summary className="inline-flex items-center mt-2 me-2 p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50 ">
                            <FeatherIcon icon="more-vertical" />
                        </summary>
                        <ul className="dropdown-content z-[1] flex flex-col gap-1 w-fit">
                          {record.status !== 'Betaald' &&  <Link onClick={() => { closeDropdown(record); handleMarkPaid(record) }} className="link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1">
                                Markeer Betaald
                            </Link>}
                            <PDFDownloadLink
                                document={<InvoiceDocument invoiceData={record} />}
                                fileName={record?.InvoiceId + ".pdf"}
                                onClick={() => { closeDropdown(record) }}
                                className="animate-none bg-none link-ancher hover:bg-gray-100 hover:rounded-[10px] px-4 py-1"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : 'Download PDF'
                                }
                            </PDFDownloadLink>
                        </ul>
                    </Dropdown>
                </>
            ),
        },
    ];

    const fetchData = async ({ page, pageSize, resetPageSize }) => {
        try {
            setLoading(true);
            const response = await fetchAllInvoices(page, pageSize, searchText);
            setStatistics({
                countBetaald: response?.countBetaald,
                countNietBetaald: response?.countNietBetaald,
                totalAmountBetaald: response?.totalAmountBetaald,
            })
            const result = {
                data: response.docs.map((invoice, index) => ({ ...invoice, key: index })),
                totalDataCount: response.totalDocs,
                resetPageSize,
            };
            setData(result);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData({ page: 1, pageSize: 10, resetPageSize: false });
    }, [searchText]);

    const handleSearch = (query) => {
        setSearchText(query);
    };

    const handleDelete = async () => {
        Swal.fire({
            title: 'Weet je het zeker?',
            text: "Dit kan niet ongedaan worden gemaakt!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ja, verwijder het!',
            cancelButtonText: 'Annuleren'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await adminDeleteInvoice(SelectedRows);
                    fetchData({ page: 1, pageSize: 10, resetPageSize: false });
                    setSeletedRows([]);
                    Swal.fire(
                        'Verwijderd!',
                        'De geselecteerde facturen zijn verwijderd.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error deleting invoices:", error);
                    Swal.fire(
                        'Mislukt!',
                        'Er is een fout opgetreden bij het verwijderen van de facturen.',
                        'error'
                    );
                }
            }
        });
    };

    const handleMarkPaid = async (record) => {
        Swal.fire({
            title: 'Weet je het zeker?',
            text: "Wil je deze factuur markeren als betaald?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ja, markeer als betaald',
            cancelButtonText: 'Annuleren'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await adminUpdateInvoiceStatus(record._id, { status: 'Betaald' });
                    fetchData({ page: 1, pageSize: 10, resetPageSize: false });
                    Swal.fire(
                        'Gemarkeerd!',
                        'De factuur is gemarkeerd als betaald.',
                        'success'
                    );
                } catch (error) {
                    console.error("Error updating invoice status:", error);
                    Swal.fire(
                        'Mislukt!',
                        'Er is een fout opgetreden bij het markeren van de factuur als betaald.',
                        'error'
                    );
                }
            }
        });
    };


    const handleExport = () => {
        const csvData = data.data.map(row => ({
            ID: row._id || 'N/A',
            Naam: row?.user?.naam || 'N/A',
            Email: row?.user?.email || 'N/A',
            Bedrijfsnaam: row?.user?.bedrijfsnaam || 'N/A',
            FactuurNummer: row?._id || 'N/A',
            Regeling: row?.application?.regling || 'N/A',
            Bedrag: row?.application?.totaal || 'N/A',
            Status: row?.status || 'N/A',
            BetaaldDatum: row?.betaaldDateTime ? new Date(row?.betaaldDateTime).toLocaleDateString() : 'N/A'
        }));
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "facturen.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const RowChanges = (rows) => {
        setSeletedRows(rows);
        
    };

    const formatNumber = (number) => {
        return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      };

    return (
        <>
            <div className="page-wrapper">
                <div className="content admin-pages">
                    <div className="py-4">
                        <div className="grid md:grid-cols-3 grid-cols-1 mt-5 md:gap-[30px]">
                            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                                <p className="title text-[16px]">Betaald</p>
                                <div className="text-with-icon flex items-center gap-2 mt-3">
                                    <div className="icon bg-[#DDF9E4]">
                                        <FeatherIcon icon="user" size="20" />
                                    </div>
                                    <p className="count text-[16px]">
                                        {statistics.countBetaald}
                                    </p>
                                </div>
                            </div>

                            <div className="card card-type-2 bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                                <p className="title text-[16px]">Niet Betaald</p>
                                <div className="text-with-icon flex items-center gap-2 mt-3">
                                    <div className="icon bg-[#FFE4E4]">
                                        <FeatherIcon icon="user" size="20" />
                                    </div>
                                    <p className="count text-[16px]">
                                        {statistics.countNietBetaald}
                                    </p>
                                </div>
                            </div>

                            <div className="card card-type-2  bg-[#F9F9F9] px-10 py-6 rounded-[20px]">
                                <p className="title text-[15px]">Totaal Omzet</p>
                                <div className="text-with-icon flex items-center gap-2 mt-3">
                                    <div className="icon financieel">
                                        <span>€</span>
                                    </div>
                                    <p className="count text-[16px]">
                                        {formatNumber(statistics.totalAmountBetaald || '0')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-10">
                        <SearchComponent onSearch={handleSearch} type={'invoices'} />
                    </div>
                    <div className="table-responsive card p-4 bg-[#FAFBFE]">
                        <div className="bg-[#FAFBFE] px-0 py-3 flex justify-between items-center">
                            <div>
                                <button disabled={!SelectedRows?.length} className="btn animate-none me-2 bg-[#EB4747] bg-opacity-20 text-black border-none hover:bg-opacity-30 hover:bg-[#EB4747] px-4 shadow-none py-1 disabled:bg-opacity-30 disabled:opacity-50 disabled:text-black disabled:bg-[#EB4747]" onClick={handleDelete}>
                                    <FeatherIcon
                                        icon="trash-2"
                                        className="h-5 text-[#EB4747]"
                                    ></FeatherIcon>
                                    Verwijder
                                </button>

                                <button disabled={!SelectedRows?.length} className="btn animate-none bg-[#DEF8EC] text-black border-none hover:bg-[#DEF8EC] px-4 shadow-none py-1 disabled:opacity-50 disabled:text-black disabled:bg-[#DEF8EC]" onClick={handleExport}>
                                    <FeatherIcon
                                        icon="download"
                                        className="h-5 text-[#82A695]"
                                    ></FeatherIcon>
                                    Exporteer
                                </button>
                            </div>
                        </div>
                        <div className="bg-white rounded-[20px]">
                            <Table
                                columns={columns}
                                response={data}
                                fetchData={fetchData}
                                SelectedRows={RowChanges}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminFinancieelPage;
