// UserContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UpdateUserToken } from '../axios/auth';
import { useNavigate } from 'react-router-dom';
import MainLoader from '../../components/main-loader';
import { pushToDashboard } from '../../utils/constants';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refreshToken();
  }, []);

  const updateUser = (newUserData) => {
    
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...newUserData,
    }));
  };

  const refreshToken = async () => {
    const isRestrictedRoute = location.pathname.includes('complete-profile') || location.pathname.includes('forget-password') || location.pathname.includes('reset-password') ||  location.pathname.includes('signIn')
    try {
      const user = await UpdateUserToken();
      updateUser(user);
      if(isRestrictedRoute) {
        pushToDashboard(user.role, navigate);
      }
    } catch (err) {
      if(!isRestrictedRoute) {
        navigate('/signIn');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isCurrentUserAdmin = () => {
    return userData?.role === 'admin';
  };

  return (
    <UserContext.Provider value={{ userData, updateUser, isCurrentUserAdmin }}>
      {isLoading ? <MainLoader /> : children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
