
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Logo } from "../core/path/images";
import FeatherIcon from "feather-icons-react";
import {  Link, useNavigate } from "react-router-dom";
import { useUser } from "../core/context/user";
import { toastrError, toastrSuccess } from "../utils/alertify";
import { LoginAndUpdateToken, removeState } from "../core/axios/auth";
import { ErrorsAndMessages, pushToDashboard } from "../utils/constants";



const SignInPage = (props) => {
  const [eye, seteye] = useState(false);
  const { updateUser, userData } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    removeState();
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(ErrorsAndMessages.emailRequired).email(ErrorsAndMessages.emailInvalid),
    wachtwoord: Yup.string()
      .required(ErrorsAndMessages.passwordRequired)
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });



  const submitForm = (data) => {
    setIsLoading(true);
    LoginAndUpdateToken(data).then((user) => {
      updateUser(user)
      pushToDashboard(user?.role,navigate);
      toastrSuccess(ErrorsAndMessages.loginSuccess);
    }).catch((err) => {
      toastrError(err.response?.data?.error || ErrorsAndMessages.InvalidCredentials);
    }).finally(() => {
      setIsLoading(false);
    })
  }


  return (
    <>
      <div className="main-wrapper">
        <div className="sign-in-page">
          <div className="logo md:m-0 mx-8">
            <img src={Logo} alt="" />
          </div>

          <div className="sign-in-form">
            <h1>Login</h1>
            <form  onSubmit={handleSubmit((data) => submitForm(data))}>
              <div className="mt-5">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                id="email"
                name="email"
                type="text"
                autoComplete="new-password"
                  className={`${errors.email ? "is-invalid" : ""} appearance-none w-full py-2 px-3`}
                  {...register("email")}
                />
                <div className="invalid-feedback h-3">{errors.email?.message || " "}</div>
              </div>

              <div className="mt-5 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Wachtwoord
                </label>
                <input
                  id="wachtwoord"
                  name="wachtwoord"
                  autoComplete="new-password"
                  type={eye ? "text" : "password"}
                  className={`${errors.email ? "is-invalid" : ""} appearance-none w-full py-2 px-3`}
                  {...register("wachtwoord")}
                />
                   <div
                    className="password-toggle-btn"
                    onClick={() => seteye(!eye)}
                  >
                    {eye ? (
                      <FeatherIcon icon="eye-off" />
                    ) : (
                      <FeatherIcon icon="eye" />
                    )}
                  </div>
                  <div className="invalid-feedback h-3">{errors.wachtwoord?.message}</div>

                   <div className="forget-password">
                <Link to={'/forget-password'}>Wachtwoord vergeten?</Link>
                   </div>
              </div>

            
             <div className="mt-7 p-2">
             <button className="btn btn-active btn-neutral w-full">
                    {
                      isLoading ?
                      <span className='flex items-center gap-1'>
                      <FeatherIcon icon="loader" className={`w-4 h-4 animate-spin`} />
                       Validating...
                      </span>
                      : "Login"
                    }
             </button>
             </div>
            </form>
            <div className="sign-up-link">

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
