import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import routerService from '../../routes/index';
import { useUser } from "../../core/context/user";
import { useEffect } from "react";
import ProtectedRoute from '../../components/protectedRoutes';

const DefaultLayout = () => {
  const { isCurrentUserAdmin, userData } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate("/signIn");
    }
  }, [userData, navigate]);

  const currentRole = () => {
    return isCurrentUserAdmin() ? 'admin' : 'customer';
  };

  return (
    <div className="main-wrapper">
      <Header />
      <Routes>
        {routerService
          .filter(val => val.path.includes(currentRole()))
          .map((route, key) => (
            <Route
              key={key}
              path={`/${route.path}`}
              element={
                <ProtectedRoute>
                  <route.component />
                </ProtectedRoute>
              }
            />
          ))}
        <Route path="*" element={<Navigate to="/error-404" replace />} />
      </Routes>
      <Sidebar />
      <div className="sidebar-overlay"></div>
    </div>
  );
};

export default DefaultLayout;
