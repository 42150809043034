import { ErrorsAndMessages } from "../../utils/constants";
import { Login, refreshUserToken } from "./endpoints";
import { updateToken } from "./index";

export const LoginAndUpdateToken = async (payload) => {
    const response = await Login(payload);
    const { accessToken, refreshToken, user } = response;
    if (!accessToken) {
        throw new Error({ message: ErrorsAndMessages.InvalidCredentials });
    }
    setState({ accessToken, refreshToken });
    await updateToken();
    return user;
}

export const LogoutAndRemoveState = async () => {
    removeState();
    await updateToken();
}

export const UpdateUserToken = async () => {
    try {
        const token = localStorage.getItem('refreshToken');
        if (!token) {
            throw new Error({ message: 'Please Login' });
        }
        const response = await refreshUserToken({ refreshToken: token });
        const { accessToken, refreshToken, user } = response;
        if (!accessToken) {
            removeState();
            throw new Error({ message: 'Token Expired' });
        }
        setState({ accessToken, refreshToken });
        await updateToken();
        return user;
    } catch (error) {
        removeState();
        throw error;
    }
}


export const removeState = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}

export const setState = (data) => {
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
}