import CustomPopover from "./popover";


export const KvkNummerPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Kvk Nummer:
          </p>
          <ul className="list-disc pl-5">
            <li>Vul hier het KvK-nummer van de klant in.</li>
            <li>Het KvK-nummer bestaat uit 8 cijfers.</li>
          </ul>
          <p className="mt-3">
          <i>Controleer of het nummer correct is ingevoerd.</i>
          </p>
        </div>
      </CustomPopover>
    );
}

export const HandelsNaamPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Handelsnaam:
          </p>
          <ul className="list-disc pl-5">
            <li>Handelsnaam van de klant.</li>
          </ul>
          <p className="mt-3">
            <i>Controleer of dit correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}

export const VoorLettersPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Voorletters:
          </p>
          <ul className="list-disc pl-5">
            <li>Initialen van de voornamen.</li>
          </ul>
          <p className="mt-3">
          <i>Voorbeeld: H.F.</i>
          </p>
        </div>
      </CustomPopover>
    );
}

export const AchterNaamPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Achternaam:
          </p>
          <ul className="list-disc pl-5">
            <li>De achternaam van de klant.</li>
          </ul>
          <p className="mt-3">
          <i>Controleer of het correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}

export const StraatNaamPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Straatnaam:
          </p>
          <ul className="list-disc pl-5">
            <li>Officiele adres die bekent is van de onderneming.</li>
          </ul>
          <p className="mt-3">
          <i>Controleer of het correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}

export const HuisNummerPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Huisnummer:
          </p>
          <ul className="list-disc pl-5">
            <li>Huisnummer van de onderneming.</li>
            <li>Vermeld eventuele toevoegingen bij het huisnummer.</li>
          </ul>
          <p className="mt-3">
            <i>Bijvoorbeeld: 17 B</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const PostCodePopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Postcode:
          </p>
          <ul className="list-disc pl-5">
            <li>Postcode van de onderneming.</li>
          </ul>
          <p className="mt-3">
            <i>Controleer of het correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const PlaatsPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Plaats:
          </p>
          <ul className="list-disc pl-5">
            <li>Plaats van de onderneming</li>
          </ul>
          <p className="mt-3">
            <i>Bijvoorbeeld: Amsterdam</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const TelefoonNummerPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Telefoonnummer:
          </p>
          <ul className="list-disc pl-5">
            <li>Telefoonnummer van de klant.</li>
          </ul>
          <p className="mt-3">
            <i>Controleer of het correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const EmailPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Email:
          </p>
          <ul className="list-disc pl-5">
            <li>Email-adres van de klant.</li>
          </ul>
          <p className="mt-3">
            <i>Controleer of het correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const MeldCodePopOver = ({position}) => {
    return (
        <CustomPopover position={position}>
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Meldcode:
          </p>
          <ul className="list-disc pl-5">
            <li>Voer hier de meldcode op die te vinden is op de apparatenlijst ISDE.</li>
          </ul>
          <p className="mt-3">
          Voor meer informatie over meldcodes op de apparatenlijst en hoe deze te vinden, bezoek <a  href="https://www.rvo.nl/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">rvo.nl</a>.
          </p>
        </div>
      </CustomPopover>
    );
}


export const BedrijfsMiddelCodePopOver = ({position}) => {
  return (
      <CustomPopover position={position}>
      <div>
      <p className="mb-3 font-bold text-[20px]">
      Bedrijfsmiddelcode:
        </p>
        <ul className="list-disc pl-5">
          <li>Voer hier de Bedrijfsmiddelcode op die te vinden is op de energielijst.</li>
        </ul>
        <p className="mt-3">
        <i>Je kunt de energielijst vinden op de website van </i><a href="https://rvo.nl" target="_blank" rel="noopener noreferrer">rvo.nl</a>
        </p>
      </div>
    </CustomPopover>
  );
}

export const DatumOpdrachtBevestigingPopOver = ({position}) => {
    return (
        <CustomPopover position={position}>
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Datum Opdracht Bevestiging:
          </p>
          <ul className="list-disc pl-5">
            <li>Datum dat de opdracht is bevestigd</li>
          </ul>
          <p className="mt-3">
           <i>Controleer of dit correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const AantekeningVoorOnsPopOver = ({position}) => {
    return (
        <CustomPopover position={position}>
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Aantekening Voor Ons:
          </p>
          <p className="mt-3">
            Mocht u wat extra informatie willen geven, dan kan dat hier.
          </p>
        </div>
      </CustomPopover>
    );
}


export const KenmerkPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Uw Kenmerk:
          </p>
          <p className="mt-3">
            Dit is puur voor uw eigen administratie.
          </p>
          <i>Bijvoorbeeld: EIA-4567</i>
        </div>
      </CustomPopover>
    );
}

export const KopieOndertekendeOffertePopOver = ({position}) => {
    return (
        <CustomPopover position={position}>
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Kopie Ondertekende Offerte:
          </p>
          <ul className="list-disc pl-5">
            <li>Upload een goedzichtbare bestand.</li>
          </ul>
        </div>
      </CustomPopover>
    );
}


export const AantalZonnepanelenPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Aantal Zonnepanelen:
          </p>
          <ul className="list-disc pl-5">
            <li>Voer het aantal zonnepanelen hier in.</li>
          </ul>
          <p className="mt-3">
            <i>Controleer of dit correct is.</i>
          </p>
        </div>
      </CustomPopover>
    );
}

export const TotaalVermogenPopOver = () => {
    return (
        <CustomPopover position="top-[34px] right-[5px]">
        <div>
        <p className="mb-3 font-bold text-[20px]">
          Totaal Vermogen:
          </p>
          <ul className="list-disc pl-5">
            <li><strong>Minimaal:</strong> 15.000 WP</li>
            <li><strong>Maximaal:</strong> 55.000 WP</li>
          </ul>
          <p className="mt-3">
            <i>Controleer dit zorgvuldig, dit beïnvloed de goedkeuring.</i>
          </p>
        </div>
      </CustomPopover>
    );
}


export const TypePopOver = ({position}) => {
    return (
        <CustomPopover position={position}>
        <div>
        <p className="mb-3 font-bold text-[20px]">
        Bijlage energienota voor Warmtepomp:
          </p>
          <p className="mb-2 font-bold text-[16px]">
          Gelieve een kopie van uw energienota bij te voegen.</p>
          <p className="mb-2 font-regular text-[14px]">Voor lucht-water-warmtepompen geldt:</p>
          <ul className="list-disc pl-5">
          <li>Uw locatie heeft een jaarlijks energieverbruik lager dan 50.000 kWh stroom en 25.000 m³ aardgas.</li>
          <li>Bij overschrijding van deze verbruiksgrenzen in het voorafgaande jaar komt u vanaf 1 juli 2023 niet in aanmerking voor subsidie voor een lucht-water-warmtepomp.</li>
          <li>Indien uw locatie van het gasnet wordt (of al is) afgesloten, is een subsidie wel mogelijk.</li>
          </ul>
          <p className="mt-3">
            <i>Let op: deze voorwaarde geldt niet voor water-water-warmtepompen en grondwater-warmtepompen.</i>
          </p>
          <p className="mt-3">
            <i>Indien u geen jaarlijkste jaarnota heeft. Dien dan de meest recente maandafrekening.</i>
          </p>
        </div>
      </CustomPopover>
    );
}