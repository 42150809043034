import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/search";
import Table from '../../components/datatable/datatable';
import { useNavigate } from "react-router-dom";
import { getAllUserInvoices } from "../../core/axios/endpoints";
import { RoutesFullPath } from "../../utils/constants";

const ClientFacturenPage = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Factuur Nummer",
      dataIndex: "InvoiceId", // Using invoiceId for factuurNummer
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.invoiceId.localeCompare(b.invoiceId),
    },
    {
      title: "Regeling",
      dataIndex: "application", // Using regling from application
      render: (application) => <span>{application.regling}</span>,
      sorter: (a, b) => a.application.regling.localeCompare(b.application.regling),
    },
    {
      title: "Betaald Op",
      dataIndex: "betaaldDateTime", // Using updatedAt for betaaldOp
      render: (text) => <span>{ text ? new Date(text).toLocaleDateString() : 'N/A'}</span>,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
    },
    {
      title: "Totaal",
      dataIndex: "application",
      render: (application) => <span>€{formatNumber(application?.totaal || '0')}</span>,
      sorter: (a, b) => a.totaal - b.totaal,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => <span>{text}</span>,
    },
  ];

  const fetchData = async ({ page, pageSize, resetPageSize }, searchText = '') => {
    setLoading(true);
    await getAllUserInvoices(page, pageSize, searchText).then(async data => {
      const result = await new Promise((resolve) => {
        resolve({
          data: data?.docs?.map((item, index) => ({ ...item, id: index })) || [],
          totalDataCount: data?.totalDocs || 0,
          resetPageSize
        });
      });
      setData(result);
    }).catch(err => {
      
    }).finally(() => setLoading(false));
  };

  const handleRowClick = (record) => {
    navigate(`${RoutesFullPath.BetalingDetails}/${record?._id}`);
  };

  useEffect(() => {
    fetchData({ page: 1, pageSize: 10, resetPageSize: false });
  }, []);

  const handleSearch = (query) => {
    fetchData({ page: 1, pageSize: 10, resetPageSize: false }, query);
  };

  const formatNumber = (number) => {
    return number.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="p-4">
            <SearchComponent onSearch={handleSearch} type={'invoices'} />
          </div>
          <div className="table-responsive">
            <Table
              columns={columns}
              response={data}
              fetchData={fetchData}
              onRowClick={handleRowClick}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientFacturenPage;
