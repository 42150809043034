import React, { useEffect, useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "./button";
import { completeProfile, updateProfile, uploadImage } from "../core/axios/endpoints";
import { UserMockImage } from "../core/path/images"; // Adjust the import according to your project structure
import { useUser } from "../core/context/user";
import { toastrError, toastrSuccess } from "../utils/alertify";
import { setState } from "../core/axios/auth";
import { useNavigate } from "react-router-dom";
import { pushToDashboard } from "../utils/constants";
import { updateToken } from "../core/axios";

const Modal = ({ isOpen, onClose, token , isCompleteProfile }) => {
  const [validationSchema, setValidationSchema] = useState({});
  const [isProfileCompletionStep, setIsProfileCompletionStep] = useState(isCompleteProfile? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [image, setImage] = useState(null);
  const { userData,updateUser } = useUser(); // Use the user context
  const navigate = useNavigate();
  useEffect(() => {
    const modal = document.getElementById("profile-modal");
    if (isOpen) {
      modal.showModal();
    } else if (modal.open) {
      modal.close();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleESC = (event) => {
    event.preventDefault();
    handleClose();
  }

  const schema = (isConditionTrue) => {
    return Yup.object().shape({
      naam: Yup.string().required("Naam is verplicht"),
      bedrijfsnaam: Yup.string().required("Bedrijfsnaam is verplicht"),
      telefoonnummer: Yup.string().required("Telefoonnummer is verplicht"),
      adres: Yup.string().required("Adres is verplicht"),
      postcode: Yup.string()
      .required("Postcode is verplicht")
      .matches(/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/, "Ongeldig postcodeformaat."),
      plaats: Yup.string().required("Plaats is verplicht"),
      image: Yup.string().optional(),
      wachtwoord: isConditionTrue
        ? Yup.string()
            .required("Wachtwoord is verplicht")
            .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn.")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              "Wachtwoord moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten."
            )
        : Yup.string().optional(),
      bevestigWachtwoord: isConditionTrue
        ? Yup.string().oneOf(
            [Yup.ref("wachtwoord"), null],
            "Wachtwoorden moeten overeenkomen."
          )
        : Yup.string().optional(),
    });
  };

  useEffect(() => {
    setValidationSchema(schema(isProfileCompletionStep));
  }, [isProfileCompletionStep]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!isProfileCompletionStep && userData) {
      
      setValue("naam", userData.naam);
      setValue("bedrijfsnaam", userData.bedrijfsnaam);
      setValue("telefoonnummer", userData.telefoonnummer);
      setValue("adres", userData.adres);
      setValue("postcode", userData.postcode);
      setValue("plaats", userData.plaats);
      setImage(userData.profielFoto);
    }
  }, [isProfileCompletionStep, userData, setValue]);

  const submitForm = async (data) => {
    setIsLoading(true);
    
    try {
      if (isProfileCompletionStep) {
        const postData  = {
          naam: data.naam,
          profielFoto: image,
          bedrijfsnaam: data.bedrijfsnaam,
          telefoonnummer: data.telefoonnummer,
          adres: data.adres,
          postcode: data.postcode,
          plaats: data.plaats,
          wachtwoord: data.wachtwoord,
          token: token,
        };
       const result = await completeProfile(postData);
        toastrSuccess(result?.successMsg || "Profiel succesvol bijgewerkt.");
        setState({ accessToken: result?.accessToken, refreshToken: result?.refreshToken});
        updateUser({
          ...postData,
          applicationFee:result?.user?.applicationFee,
          tax:result?.user?.tax,
          _id:result?.user?._id,
          role:result?.user?.role
        });
        await updateToken()
        pushToDashboard(result?.user?.role,navigate);
      } else {
        const response = await updateProfile(userData?._id, { ...data, profielFoto: image });
        updateUser({ ...data, profielFoto: image });
        toastrSuccess( response?.successMsg ||"Profiel succesvol bijgewerkt.");
      }
      setIsLoading(false);
      onClose();
    } catch (err) {
      console.error("Error updating profile:", err);
      toastrError(err?.response?.data?.error || "Er is een fout opgetreden, probeer het later opnieuw.");
      setIsLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploadLoading(true);
      uploadImage(file, token? token : null)
        .then((res) => {
          setImage(res.data.url);
        })
        .catch((err) => {
          console.error("Fout bij het uploaden van de afbeelding:", err);
        })
        .finally(() => {
          setIsUploadLoading(false);
        });
    }
  };

  const selectAndUploadFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.jpg, .jpeg, .png';
    input.onchange = handleImageUpload;
    input.click();
  };

  return (
    <dialog id="profile-modal" className="modal" onCancel={handleESC}>
      <div className="modal-box w-11/12 max-w-5xl">
        <div className="modal-content md:px-16">
          {!isProfileCompletionStep && <button className="btn btn-lg btn-circle btn-ghost absolute right-2 top-2" onClick={handleClose}>✕</button>}
          <h1 className="modal-title text-center">Persoonlijke gegevens</h1>
          <div className="flex justify-center flex-col items-center gap-5">
            <div className="profile-pic">
              <img className="object-cover" src={image || UserMockImage} alt="Profile" />
            </div>
            <button
              disabled={isUploadLoading}
              type="button"
              onClick={selectAndUploadFile}
              className="btn upload-button mt-2 w-fit mb-2 "
            >
              {isUploadLoading ? <span className="loading loading-spinner"></span> : ''}
              {isUploadLoading ? 'Uploading, Please Wait....' : 'Upload een Foto'}
            </button>
          </div>
          <form className="update-profile-form mt-5" onSubmit={handleSubmit(submitForm)}>
            <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
              <div>
                <label htmlFor="naam"> Naam </label>
                <input
                  id="naam"
                  name="naam"
                  type="text"
                  className={`${errors.naam ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("naam")}
                />
                <div className="invalid-feedback h-3">
                  {errors.naam?.message}
                </div>
              </div>

              <div>
                <label htmlFor="bedrijfsnaam">Bedrijfsnaam </label>
                <input
                  id="bedrijfsnaam"
                  name="bedrijfsnaam"
                  type="text"
                  className={`${errors.bedrijfsnaam ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("bedrijfsnaam")}
                />
                <div className="invalid-feedback h-3">
                  {errors.bedrijfsnaam?.message}
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
              <div>
                <label htmlFor="telefoonnummer">Telefoon Nummer </label>
                <input
                  id="telefoonnummer"
                  name="telefoonnummer"
                  type="text"
                  className={`${errors.telefoonnummer ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("telefoonnummer")}
                />
                <div className="invalid-feedback h-3">
                  {errors.telefoonnummer?.message}
                </div>
              </div>

              <div>
                <label htmlFor="adres">Adres </label>
                <input
                  id="adres"
                  name="adres"
                  type="text"
                  className={`${errors.adres ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("adres")}
                />
                <div className="invalid-feedback h-3">
                  {errors.adres?.message}
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
              <div>
                <label htmlFor="postcode">Postcode </label>
                <input
                  id="postcode"
                  name="postcode"
                  type="text"
                  className={`${errors.postcode ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("postcode")}
                />
                <div className="invalid-feedback h-3">
                  {errors.postcode?.message}
                </div>
              </div>

              <div>
                <label htmlFor="plaats">Plaats </label>
                <input
                  id="plaats"
                  name="plaats"
                  type="text"
                  className={`${errors.plaats ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                  {...register("plaats")}
                />
                <div className="invalid-feedback h-3">
                  {errors.plaats?.message}
                </div>
              </div>
            </div>

            {isProfileCompletionStep && (
              <div>


                <div className="grid md:grid-cols-2 gap-10 mt-3 grid-cols-1">
                  <div className="relative">
                    <label htmlFor="wachtwoord">Wachtwoord</label>
                    <input
                      id="wachtwoord"
                      name="wachtwoord"
                      type="password"
                      className={`${errors.wachtwoord ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                      {...register("wachtwoord")}
                    />
                    <div className="invalid-feedback h-3">
                      {errors.wachtwoord?.message}
                    </div>
                  </div>
                  <div className="relative">
                    <label htmlFor="bevestigWachtwoord">Bevestig Wachtwoord</label>
                    <input
                      id="bevestigWachtwoord"
                      name="bevestigWachtwoord"
                      type="password"
                      className={`${errors.bevestigWachtwoord ? "is-invalid" : isSubmitted && "valid"} appearance-none w-full py-2 px-3`}
                      {...register("bevestigWachtwoord")}
                    />
                    <div className="invalid-feedback h-3">
                      {errors.bevestigWachtwoord?.message}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-7 p-2 flex md:justify-end justify-center">
              <Button btnClassNames={`btn-neutral w-min px-16`} text="Update" isLoading={isLoading} onClick={() => { handleSubmit(); setIsSubmitted(true); }} loadingText="Updaten..." />
            </div>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default Modal;
