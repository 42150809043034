// Dropdown.js
import React, { useEffect, useRef } from 'react';

const Dropdown = ({ className, id, children }) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const closeOtherDropDowns = (e) => {
            const dropdowns = document.querySelectorAll(`.${className}`);
            dropdowns.forEach((dropdown) => {
                if (dropdown !== e.target.parentElement) {
                    dropdown.removeAttribute("open");
                }
            });
        }

        const closeOnOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                dropdownRef.current.removeAttribute("open");
            }
        }

        window.addEventListener('click', closeOtherDropDowns);
        window.addEventListener('click', closeOnOutsideClick);

        return () => {
            window.removeEventListener('click', closeOtherDropDowns);
            window.removeEventListener('click', closeOnOutsideClick);
        }
    }, [className]);

    return (
        <details className={className} id={id} ref={dropdownRef}>
            {children}
        </details>
    );
}

export default Dropdown;